import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Rating from '@mui/material/Rating';
import Divider from '@mui/material/Divider';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';

import './form-groups-id.css';

const MainButtonBar = ({ onClick }) => {
    return (
        <AppBar position='fixed' color='inherit' sx={{ top: 'auto', bottom: 0 }}>
            <Toolbar
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Button
                    variant='contained'
                    size='large'
                    startIcon={<AccessAlarmIcon />}
                    sx={{
                        width: '343px',
                        borderRadius: '5px',
                        cursor: 'pointer',
                    }}
                    disableElevation
                    color='success'
                    onClick={onClick}
                >
                    Записаться
                </Button>
            </Toolbar>
        </AppBar>
    );
};

const FormGroupsId = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const activity = useSelector((state) => state.activity);
// console.log(activity);
    if (!activity.id) {
        return (
            <Typography
                sx={{
                    textAlign: 'center',
                    color: '#808080',
                    fontSize: 15,
                    fontWeight: '400',
                }}
            >
                Нет информации о тренировке
            </Typography>
        );
    }


    const onClickTgButton = () => {
        dispatch({ type: 'UPDATE_ACTIVITY', payload: activity });
        navigate('/yourphone', { state: { activity: activity } });
    };



    if (process.env.NODE_ENV === 'production') {
        const tg = window.Telegram.WebApp;
        tg.MainButton.text = 'Продолжить';
        tg.MainButton.onClick(onClickTgButton);
        tg.MainButton.show();
    }


    const date = activity.date.split(' ');
    const options = { weekday: 'short', month: 'long', day: 'numeric' };
    activity.date_activity = new Date(date[0]).toLocaleDateString('ru-RU', options);
    activity.time_activity = date[1].slice(0, -3);

    const handlerNavigatetoMasterPage = (e) => {
        e.stopPropagation();
        if (activity.staff.id) {
            navigate(`/employees/${activity.staff.id}`, { state: activity.staff });
        }
    };

    return (
        <Card sx={{ padding: 1 }} elevation={0}>
            {/* activity */}
            <Card sx={{ padding: 2, paddingTop: 1, borderRadius: '16px 16px 0 0' }} elevation={0}>
                {activity.service.image_url && <CardMedia sx={{ height: 200, margin: '8px 0' }} image={activity.service.image_url} title='image_url' />}

                <Typography className='activity__name'>{activity.service.title}</Typography>
                <Typography className='activity__place'>{activity.resource_instances[0].title}</Typography>

                <table style={{ marginLeft: -3, marginBottom: 8, width: '100%' }}>
                    <thead>
                        <tr>
                            <td style={{ width: '50%' }}>
                                <Typography className='activity__gray-text'>Дата</Typography>
                            </td>
                            <td>
                                <Typography className='activity__gray-text'>Время</Typography>
                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <Typography className='activity__text'>{activity.date_activity}</Typography>
                            </td>
                            <td>
                                <Typography className='activity__text'>{activity.time_activity}</Typography>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <Typography className='activity__gray-text'>Осталось мест</Typography>
                <Typography className='activity__text'>{`${activity.capacity - activity.records_count} из ${activity.capacity}`}</Typography>
            </Card>
            <Divider />

            {/* master */}
            <Card sx={{ padding: '8px 16px', borderRadius: 0 }} elevation={0}>
                <CardHeader
                    sx={{ padding: '4px 0' }}
                    avatar={<Avatar src={activity.staff.avatar} sx={{ width: 48, height: 48 }} />}
                    title={<Typography sx={{ color: '#808080', fontSize: 13, fontWeight: '400' }}>{activity.staff.specialization}</Typography>}
                    subheader={
                        <>
                            <Typography sx={{ color: 'rgba(0, 0, 0, 0.87)', fontSize: 17, fontWeight: '400' }}>{activity.staff.name}</Typography>
                            <List sx={{ padding: 0 }} dense>
                                <ListItem sx={{ padding: 0 }}>
                                    <ListItemText
                                        sx={{ margin: 0 }}
                                        primary={activity.specialization}
                                        secondary={
                                            activity.staff.show_rating === 1 ? (
                                                <span style={{ display: 'flex', alignItems: 'center' }}>
                                                    <Rating name='read-only' value={activity.staff.rating} size='small' readOnly />
                                                    <span style={{ marginLeft: 8, marginTop: 2, fontSize: 16, fontWeight: '400' }}>{activity.staff.comments_count}</span>
                                                </span>
                                            ) : null
                                        }
                                    />
                                </ListItem>
                            </List>
                        </>
                    }
                    action={
                        <IconButton aria-label='settings' onClick={handlerNavigatetoMasterPage}>
                            <InfoOutlinedIcon />
                        </IconButton>
                    }
                />
            </Card>
            <Divider />

            {/* description */}
            {activity.service.comment && (
                <Card sx={{ padding: 2, paddingTop: 1, borderRadius: 0 }} elevation={0}>
                    <Typography className='activity__description-title'>Описание</Typography>
                    <Typography className='activity__description-text'>{activity.service.comment}</Typography>
                </Card>
            )}

            {/* {process.env.NODE_ENV === 'production' && <MainButtonBar onClick={onClickTgButton} />} */}
            {process.env.NODE_ENV === 'development' && (
                <Button
                    variant='contained'
                    size='large'
                    startIcon={<AccessAlarmIcon />}
                    sx={{
                        width: '343px',
                        borderRadius: '5px',
                        cursor: 'pointer',
                    }}
                    disableElevation
                    color='success'
                    onClick={onClickTgButton}
                >
                    Записаться
                </Button>
            )}
        </Card>
    );
};

export default FormGroupsId;
