const getTimesForCurrentDate = (state) => {

}

const updateBooktimesList = (state , action) => {
    if (state === undefined) {
        return {
            booktimes: [],
            loading: true,
            error: null
        };
    }

    switch (action.type) {

        case 'FETCH_BOOKTIMES_REQUEST':
            return {

                booktimes: [] ,
                loading: true ,
                error: null
            };
        case 'FETCH_BOOKTIMES_SUCCESS':
            return {
                booktimes: action.payload ,
                loading: false ,
                error: null

            };
        case 'FETCH_BOOKTIMES_FAILURE':
            return {
                booktimes: [],
                loading: false ,
                error: action.payload

            };

        default:
            return state.booktimes;
    }

}

export default updateBooktimesList;