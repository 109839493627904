import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import SkeletonCommentsList from '../skeletons/skeleton-comments-list';
import MySpinner from '../spinner';

import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Tabs, { tabsClasses } from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import { ThemeProvider, createTheme, experimental_sx as sx } from '@mui/material/styles';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Divider from '@mui/material/Divider';

import './form-groups.css';
import BottomLinkNotifio from "../bottom-link-notifio";

const theme = createTheme({
    components: {
        MuiTab: {
            styleOverrides: {
                root: sx({
                    '&.Mui-selected': {
                        borderTopLeftRadius: '4px',
                        borderTopRightRadius: '4px',
                        border: `2px solid #1976d2`,
                        color: 'white',
                        background: '#0072e5',
                    },
                }),
            },
        },
    },
});

const _getTabDate = (date, opt = 'DAY') => {
    let myDate = new Date(date);
    if (date === '0000') {
        myDate = new Date();
    }
    const f_num_day = new Intl.DateTimeFormat('ru', {
        day: 'numeric',
    });
    const f_short_day = new Intl.DateTimeFormat('ru', {
        weekday: 'short',
    });
    const f_day_mon = new Intl.DateTimeFormat('ru', {
        day: 'numeric',
        month: 'long',
    });
    const f_standart = new Intl.DateTimeFormat('sv');
    const num_day = f_num_day.format(myDate);
    const short_day = f_short_day.format(myDate);

    switch (opt) {
        case 'DAY':
            return short_day;
        case 'nDAY':
            return num_day;
        case 'DAY_MON':
            return f_day_mon.format(myDate);
        case 'STD':
            return f_standart.format(myDate);
        default:
            return short_day;
    }
};

const FormGroups = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const company_id = useSelector((state) => state.bookform.bookform.data.company_id);

    const [activities, setActivities] = useState([]);
    const [isLoadingActivity, setIsLoadingActivity] = useState(false);
    const [bookingDates, setBookingDates] = useState([]);
    const [isLoadingBookingDates, setIsLoadingBookingDates] = useState(false);
    const [isErrorBookingDates, setIsErrorBookingDates] = useState(false);

    const [currentData, setCurrentData] = useState('');

    const fetchDates = () => {
        // const company_id = '885178';
        const todayDate = new Date();
        const from_ = todayDate.toISOString().split('T')[0];
        const URL = `https://api.notifio.ru/activity/${company_id}/search_dates?from=${from_}`;

        fetch(URL)
            .then((res) => res.json())
            .then((res) => {
                let vNow = _getTabDate('0000', 'STD');
                if (Array.isArray(res.data)) {
                    vNow = res.data[0];
                    setCurrentData(vNow);
                    setBookingDates(res.data);
                }
            })
            .catch((error) => {
                console.log(error);
                setIsErrorBookingDates(true);
                alert('Произошла ошибка во время загрузки дат.');
            })
            .finally(() => {
                setIsLoadingBookingDates(true);
            });
    };

    const fetchActivity = (date) => {
        // const company_id = '885178';

        const URL = `https://api.notifio.ru/activity/${company_id}/search?from=${date}&till=${date}`;
// console.log("URL" , URL);
        fetch(URL)
            .then((res) => res.json())
            .then((res) => {
                const activities = res.data.map((activity) => {
                    // console.log("=>> activity" , activity);
                    let time = activity.date.split(' ');
                    return {
                        ...activity,
                        time: time[1].slice(0, -3),
                        // TODO убрать
                        resource_instances: [{}],
                    };
                });
                setActivities(activities);
            })
            .catch((error) => {
                console.log(error);
                alert('Произошла ошибка во время загрузки групповых программ.');
            })
            .finally(() => {
                setIsLoadingActivity(true);
            });
    };

    useEffect(() => {
        fetchDates();
    }, []);

    useEffect(() => {
        setActivities([]);
        setIsLoadingActivity(false);
        if (currentData) {
            fetchActivity(currentData);
        }
    }, [currentData]);

    const handleNewValue = (event, newValue) => {
        setCurrentData(newValue);
    };

    const handleNavigate = (activity) => {
        dispatch({ type: 'UPDATE_ACTIVITY', payload: activity });
        navigate(`/form-groups/info`);
    };

    if (!isLoadingBookingDates) {
        return <MySpinner />;
    }
    if (isErrorBookingDates || bookingDates.length === 0) {
        return (
            <>
                <Typography variant='h6' sx={{ display: 'flex', justifyContent: 'center', marginTop: 2, marginBottom: 2, color: '#808080' }}>
                    Данных нет.
                </Typography>
                <BottomLinkNotifio />
            </>

        );
    }

    return (
        <>
            {/* week-scroller */}
            <Box
                sx={{
                    flexGrow: 1,
                    bgcolor: 'background.paper',
                    padding: '0 5px',
                    position: 'fixed',
                    top: 48,
                    width: '100%',
                    zIndex: 1000,
                    borderBottom: '1px solid rgb(0 0 0 / 12%)',
                }}
            >
                <ThemeProvider theme={theme}>
                    <Tabs
                        value={currentData}
                        onChange={handleNewValue}
                        variant='scrollable'
                        scrollButtons
                        aria-label='visible arrows tabs example'
                        sx={{
                            [`& .${tabsClasses.scrollButtons}`]: {
                                '&.Mui-disabled': { opacity: 0.3 },
                            },
                        }}
                    >
                        {bookingDates.map((date) => {
                            return (
                                <Tab
                                    key={date}
                                    label={
                                        <React.Fragment>
                                            <Typography variant='h6'>{_getTabDate(date, 'nDAY')}</Typography>
                                            <Typography variant='caption'>{_getTabDate(date, 'DAY')}</Typography>
                                        </React.Fragment>
                                    }
                                    value={date}
                                />
                            );
                        })}
                    </Tabs>
                </ThemeProvider>
            </Box>

            {/* Activity */}
            {(!isLoadingActivity && activities.length === 0) || (isLoadingActivity && activities.length !== 0) ? (
                <Card elevation={0}>
                    <Typography variant='h6' sx={{ marginLeft: 2, marginTop: 12, marginBottom: 0, color: '#808080' }}>
                        Расписание
                    </Typography>
                    {isLoadingActivity ? (
                        activities.map((activity) => (
                            <List key={activity.id}>
                                <Card
                                    key={activity.id}
                                    sx={{ minWidth: 275, padding: '8px 16px', cursor: 'pointer' }}
                                    elevation={0}
                                    onClick={() => handleNavigate(activity)}
                                >
                                    <CardHeader
                                        sx={{ padding: 0 }}
                                        title={
                                            <Typography
                                                sx={{
                                                    fontSize: 17,
                                                    fontWeight: '600',
                                                    whiteSpace: 'normal',
                                                }}
                                            >
                                                {activity.service.title}
                                                <span
                                                    style={{
                                                        fontSize: 16,
                                                        fontWeight: '400',
                                                        color: '#808080',
                                                    }}
                                                >
                                                    {` ${activity.length / 60} мин.`}
                                                </span>
                                            </Typography>
                                        }
                                        action={
                                            <Typography
                                                sx={{
                                                    padding: 1,
                                                    paddingTop: 0.5,
                                                    fontSize: 17,
                                                    fontWeight: '600',
                                                }}
                                            >
                                                {activity.time}
                                            </Typography>
                                        }
                                    />
                                    <CardHeader
                                        sx={{ padding: '4px 0' }}
                                        avatar={<Avatar src={activity.staff.avatar} sx={{ width: 40, height: 40 }} />}
                                        title={<Typography>{activity.staff.name}</Typography>}
                                        subheader={
                                            <Typography sx={{ color: '#808080', fontSize: 13, fontWeight: '400' }}>
                                                {activity.resource_instances[0] && activity.resource_instances[0].title}
                                            </Typography>
                                        }
                                    />
                                    <Typography sx={{ color: '#808080', fontSize: 15, fontWeight: 500, display: 'flex', justifyContent: 'space-between' }}>
                                        {activity.capacity - activity.records_count === 1 && `Осталось ${activity.capacity - activity.records_count} место`}
                                        {activity.capacity - activity.records_count >= 2 &&
                                            activity.capacity - activity.records_count <= 4 &&
                                            `Осталось ${activity.capacity - activity.records_count} места`}
                                        {activity.capacity - activity.records_count >= 5 && `Осталось ${activity.capacity - activity.records_count} мест`}
                                        <ArrowForwardIosIcon />
                                    </Typography>
                                </Card>
                                <Divider />
                            </List>
                        ))
                    ) : (
                        <SkeletonCommentsList />
                    )}
                </Card>
            ) : (
                <>
                    <Typography
                        sx={{
                            marginTop: 12,
                            textAlign: 'center',
                            fontSize: 19,
                            fontWeight: '600',
                        }}
                    >
                        На выбранный день нет событий
                    </Typography>
                    <Typography
                        sx={{
                            textAlign: 'center',
                            color: '#808080',
                            fontSize: 15,
                            fontWeight: '400',
                        }}
                    >
                        Ближайшая дата для записи:
                        <br />
                        понедельник, 9 октября
                    </Typography>
                </>
            )}

        </>
    );
};

export default FormGroups;
