import React from "react";
import FinalDisplay from "../final-display";




const FinalDisplayPage = () => {

    return(
        <React.Fragment>
            <FinalDisplay  />
        </React.Fragment>
    );
}

export default FinalDisplayPage;