import React from "react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Rating from "@mui/material/Rating";
import Divider from "@mui/material/Divider";


const MasterBlockHeader = ({master}) => {

    return (
        <React.Fragment>
            {master.bookable ? (
                <Card key={master.id} sx={{ minWidth: 275 }} elevation={0}>
                    <CardHeader
                        sx={{ cursor: "pointer" }}
                        avatar={
                            <Avatar
                                src={master.avatar}
                                sx={{
                                    width: 60,
                                    height: 60,
                                }}
                            />
                        }
                        // action={
                        //     <IconButton
                        //         aria-label="settings"
                        //         onClick={() => console.log("click")}
                        //     >
                        //         <InfoOutlinedIcon />
                        //     </IconButton>
                        // }
                        title={
                            <Typography variant="button">
                                {master.name}
                            </Typography>
                        }
                        subheader={
                            <List sx={{ padding: 0 }} dense>
                                <ListItem sx={{ padding: 0 }}>
                                    <ListItemText
                                        primary={master.specialization}
                                        secondary={
                                            master.show_rating === 1 ? (
                                                <Rating
                                                    name="read-only"
                                                    value={master.rating}
                                                    size="small"
                                                    readOnly
                                                />
                                            ) : null
                                        }
                                    />
                                </ListItem>
                            </List>
                        }
                    />
                    {/*{seanceDate === null ? (*/}
                    {/*    <TimeChips*/}
                    {/*        seances={seance.seances}*/}
                    {/*        master={master}*/}
                    {/*        fullDate={fullDate}*/}
                    {/*        myDate={myDate}*/}
                    {/*        addItemCart={addItemCart}*/}
                    {/*    />*/}
                    {/*) : null}*/}


                </Card>
            ) : (
                // ===>  BOOKABLE FALSE START <======

                <Card key={master.id} sx={{ minWidth: 275 }} elevation={0}>
                    <CardHeader
                        avatar={
                            <Avatar
                                src={master.avatar}
                                sx={{
                                    width: 60,
                                    height: 60,
                                    filter: "grayscale(1)",
                                    opacity: "0.8",
                                }}
                            />
                        }
                        title={
                            <Typography variant="button" color="grey">
                                {master.name}
                            </Typography>
                        }
                        subheader={
                            <List sx={{ padding: 0 }} dense>
                                <ListItem sx={{ padding: 0 }}>
                                    <ListItemText
                                        primary={master.specialization}
                                        secondary={
                                            master.show_rating === 1 ? (
                                                <Rating
                                                    name="read-only"
                                                    value={master.rating}
                                                    size="small"
                                                    disabled
                                                />
                                            ) : null
                                        }
                                    />
                                </ListItem>
                            </List>
                        }
                    />
                </Card>
            )}
            {/*// ===>  BOOKABLE FALSE END <======*/}
            <Divider />
        </React.Fragment>
    );
}

export default MasterBlockHeader;
