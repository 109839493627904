const getHeader = (location , staffName={nominative: 'Специалист', genitive: 'Специалиста', plural: 'Специалисты'}) => {
    const inp = Array.isArray(location.match(/employees\/([0-9]+)/)); // ?.input
    // console.log("inp" , Array.isArray(inp));
    if(Array.isArray(inp)){

    }
    switch (location){
        case '/':
            return '';
        case '/services':
            return 'Услуги';
        case '/timetable':
            return 'Дата/Время';
        case '/form-groups':
            return 'Групповые тренировки';
        case '/employees':
            return staffName.plural;
            // TODO: добавить проверку на то что данный id существует
        case Array.isArray(location.match(/employees\/([0-9]+)/)):
            return `Страница ${staffName.genitive}`;
        default :
            return '';
    }
}

export {getHeader};