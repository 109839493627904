const updateUserPhone = (state, action) => {


    if (state === undefined) {
        return {
            uname : null ,
            phone: ''
        };
    }

    switch(action.type) {

        case 'SET_USER_PHONE':
            return  action.payload ;

        default:
            return state.phone;
    }
};

export default updateUserPhone;