import React from 'react';
import { useNavigate } from 'react-router-dom';

import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import { Divider, Typography } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import GroupsIcon from '@mui/icons-material/Groups';
import BottomLinkNotifio from "../bottom-link-notifio";

const SelectRecordType = () => {
    const navigate = useNavigate();

    const listData = [
        {
            id: 1,
            title: 'Индивидуальные услуги',
            icon: PersonIcon,
            link: '/',
        },
        {
            id: 2,
            title: 'Груповые события',
            icon: GroupsIcon,
            link: '/form-groups',
        },
    ];

    return (
        <>
            <Card elevation={0}>
                {listData.map((element) => (
                    <React.Fragment key={element.id}>
                        <Typography
                            sx={{
                                margin: 2,
                                minWidth: '143px',
                                borderRadius: '5px',
                                cursor: 'pointer',
                                display: 'flex',
                                justifyContent: 'space-between',
                            }}
                        >
                            <div style={{ display: 'flex' }}>
                                <element.icon
                                    sx={{
                                        color: 'grey',
                                        marginRight: 2,
                                    }}
                                />
                                {element.title}
                            </div>
                            <Button
                                variant='contained'
                                size='small'
                                sx={{
                                    height: '100%',
                                    fontWeight: '400',
                                    borderRadius: 30,
                                    textTransform: 'unset',
                                    padding: '2px 10px',
                                    cursor: 'pointer',
                                }}
                                onClick={() => {
                                    navigate(element.link, { state: { isRedirect: true } });
                                }}
                            >
                                Выбрать
                            </Button>
                        </Typography>

                        <Divider />
                    </React.Fragment>
                ))}
            </Card>
            <BottomLinkNotifio />
        </>
    );
};

export default SelectRecordType;
