import React , {useEffect} from 'react';
import List from "@mui/material/List";
import ElementListItem from "../element-list-item";
import ListItem from "@mui/material/ListItem";
import Icon from '@mui/material/Icon';
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";

import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import StarBorder from '@mui/icons-material/StarBorder';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import Typography from "@mui/material/Typography";
import CancelIcon from '@mui/icons-material/Cancel';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';



const ElementList = ({data , delCart , employeeAddCart}) => {

   // console.log("E LIST" , data);

    const delCLick = (id) => {
        //console.log("CLICK" ,id);
        delCart(id);
    }

    const handleClearSeanceDate = () => {
        employeeAddCart({date:null});
    }

    const nData = data.filter((el) => !el.hidden);

    return (
        <Box>
            <List>
                {
                    nData.map((element) => {

                        return(
                            <React.Fragment key={element.id}>
                                <ListItem  key={element.id.toString()} >
                                    <ElementListItem element={element} delSeanceDate={handleClearSeanceDate} />
                                </ListItem>

                                {(element.sublist) ?
                                    <List component="div" disablePadding sx={{padding: '1px' }} dense>
                                        {
                                            element.cart.map((field) => {
                                                return (

                                                    <ListItem secondaryAction={
                                                        !element.btn_disabled ?
                                                        <IconButton edge="end" aria-label="delete" onClick={() => delCLick(field.id)}>
                                                            <CancelIcon />
                                                        </IconButton> :
                                                            <Typography variant='body2' >{field.total}&#8381;</Typography>
                                                    } >
                                                        <ListItemButton sx={{ pl: 1 , borderTop: '1px dashed rgba(0, 0, 0, 0.12)'  }}>
                                                            <ListItemIcon>
                                                                <ArrowForwardIosIcon sx={{ fontSize: 10 }}/>
                                                            </ListItemIcon>
                                                            <ListItemText sx={{paddingLeft: '0px'}} primary={
                                                                <Typography variant="body2" color="grey">
                                                                    {field.title}
                                                                </Typography>} />
                                                        </ListItemButton>

                                                    </ListItem>

                                                )
                                            })
                                        }

                                    </List>
                                    : null}
                                <Divider />
                            </React.Fragment>
                        )
                    })
                }
            </List>
        </Box>
    );
};



export default ElementList;