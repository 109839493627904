import { legacy_createStore as createStore} from 'redux';

import reducer from "./reducers";

const store = createStore(reducer , window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());

// Проверка на включенный сторадж
let storageSupported = false;

try {
    storageSupported = (window.localStorage && true);
} catch (e) {}


// время в секундах через которое данные из localStorage будут неактуальны
const TIME_LIMIT_SECONDS = 30 * 1000;

if (storageSupported) {
    store.subscribe(() => {
        const TIME_LIMIT = +new Date() + TIME_LIMIT_SECONDS;

        localStorage.setItem('servicesList.services', JSON.stringify([store.getState().servicesList.services, TIME_LIMIT]))
        localStorage.setItem('serviceCart.serviceCartItems', JSON.stringify([store.getState().serviceCart.serviceCartItems, TIME_LIMIT]))
        localStorage.setItem('serviceCart.orderTotal', JSON.stringify([store.getState().serviceCart.orderTotal, TIME_LIMIT]))
        localStorage.setItem('serviceCart.employeeCartItems', JSON.stringify([store.getState().serviceCart.employeeCartItems, TIME_LIMIT]))
    });
}


export default store;