const dateFormater = (date , opt='DAY') => {

    let myDate = new Date(date);

    if(date === '0000'){
        myDate = new Date();
    }


    const f_num_day = new Intl.DateTimeFormat("ru", {
        day: "numeric" });
    const f_short_day = new Intl.DateTimeFormat("ru", {
        weekday: "short" });
    const f_long_day = new Intl.DateTimeFormat("ru", {
        weekday: "long" });
    const f_day_mon = new Intl.DateTimeFormat("ru", {
        day: "numeric", month: "long" });
    const f_standart = new Intl.DateTimeFormat("sv");

    const num_day = f_num_day.format(myDate);
    const short_day = f_short_day.format(myDate);
    const long_day = f_long_day.format(myDate);


    switch(opt){
        case 'DAY':
            return short_day;
        case 'LONGDAY':
            return long_day;
        case 'nDAY':
            return num_day;
        case 'DAY_MON':
            return f_day_mon.format(myDate);
        case 'STD':
            return f_standart.format(myDate);
        default: return short_day;
    }
}

export {dateFormater};