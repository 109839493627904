import React from "react";
import Skeleton from "@mui/material/Skeleton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";

const SkeletonCommentsList = () => {
    return (
        <List>
            {Array.from(Array(4)).map((_, index) => (
                <ListItem key={index} divider>
                    <ListItemAvatar>
                        <Skeleton
                            animation="wave"
                            variant="circular"
                            width={50}
                            height={50}
                        />
                    </ListItemAvatar>
                    <ListItemText
                        primary={
                            <Skeleton
                                animation="wave"
                                variant="rectangular"
                                width="90%"
                                height={15}
                            />
                        }
                        secondary={
                            <Skeleton
                                animation="wave"
                                height={10}
                                width="60%"
                                style={{ marginTop: 6 }}
                            />
                        }
                    />
                </ListItem>
            ))}
        </List>
    );
};

export default SkeletonCommentsList;
