import React from 'react';
import Skeleton from '@mui/material/Skeleton';
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";

const SkeletonServicesList = () => {

    return(
        <List>
            {Array.from(Array(6)).map((_, index) => (
                <ListItem key={index} divider>
                    <ListItemAvatar>
                        <Skeleton animation="wave" variant="circular" width={40} height={40} />
                    </ListItemAvatar>
                    <ListItemText primary={<Skeleton animation="wave" variant="rectangular" width="80%" height={15} variant="rounded"/>
                    } secondary={<Skeleton
                        animation="wave"
                        height={10}
                        width="60%"
                        style={{ marginTop: 6 }}
                    />} />

                </ListItem>

            ))}
        </List>
    );

}

export default SkeletonServicesList;