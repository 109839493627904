import React, { useEffect } from "react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Rating from "@mui/material/Rating";
import CardContent from "@mui/material/CardContent";
import Stack from "@mui/material/Stack";
import Chip from "@mui/material/Chip";
import Divider from "@mui/material/Divider";
import {Button, CardActionArea, CardActions, CircularProgress} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { isObjEmpty } from "../../utils";
import Grid from "@mui/material/Grid";
import Skeleton from "@mui/material/Skeleton";

// const handleClick = (id , time ) =>{
//     addToCart({mid: id ,time: time , date: myDate});
//     navigate("/");
// };

const TimeChips = ({ seances, master, addItemCart, myDate, fullDate , loading }) => {
// console.log("Seances TC" , seances);
    return (
        <CardContent sx={{ paddingTop: 0 }}>
            {/*{seances.length > 0 &&*/}
                <Typography sx={{mb: 1.5}} color="text.secondary" variant="body2">
                    {fullDate}
                </Typography>
            {/*}*/}
            <Stack direction="row" spacing={1}>

                {loading &&
                    <>
                    <Grid container spacing={1} sx={{ paddingTop: "6px" }}>
                        {Array.from(Array(4)).map((_, index) => (
                            <Grid item xs={3} sm={3} md={3} key={index}>
                                <Skeleton
                                    animation="wave"
                                    height={26}
                                    width="80%"
                                    variant="rounded"
                                    sx={{borderRadius: 5}}
                                />
                            </Grid>
                        ))}
                    </Grid>
                    </>
                }
                {!loading &&
                    seances.map((el) => {
                            return (
                                <Chip
                                    key={master.id + el.time}
                                    label={el.time}
                                    variant="outlined"
                                    onClick={() => {
                                        addItemCart(master.id, {
                                            time: el.time,
                                            dates: myDate,
                                        });
                                    }}
                                />
                            );
                        })
                }

            </Stack>
        </CardContent>
    );
};

const EmployeeListItem = ({ master, seance, seanceDate, addItemCart , fetchSeances , objFilter , sLoading}) => {

    // console.log("Master" , master);
    const sDate = ( seanceDate === null || JSON.stringify(seanceDate) === '{}' ) ? true : false ;
    const navigate = useNavigate();


    useEffect(() => {
        fetchSeances(master.id , objFilter);
    } , [])

    // const addItemCart = (masterId , dates={}) => {
    //     console.log("addItemCart" , dates);
    //     if(!isObjEmpty(dates)){
    //         addToCart({mid: masterId ,time: dates.time , date:dates.date});
    //     }else{
    //         addToCart({mid: masterId});
    //     }
    //     navigate("/");
    // }

    //--------- start ------

    let fullDate = "Нет данных о свободных окнах";

    // if (seance === undefined) {
    //     // return null;
    // }


    // НАЧАЛО
    let myDate = new Date();
    if(seance !== undefined) myDate = new Date(seance.seance_date );
    let formatter = new Intl.DateTimeFormat("ru", {
        day: "numeric",
        month: "long",
        weekday: "long",
    });

    const ru_date = formatter.format(myDate);
    // fullDate = "Ближайшее время для записи: " + ru_date;

    if (seance !== undefined) {
        fullDate = "Ближайшее время для записи: " + ru_date;
    }

    // >>> Конец комментинга

    const handlerIconClick = (e) => {
        e.stopPropagation();
        // console.log(company_id);
        navigate(`/employees/${master.id}`, {state: master});
    };

    // ---------- end ------

    // let fullDate = "Нет данных о свободных окнах";
    // console.log("DSdj;e ");
    return (
        <React.Fragment>
            {master.bookable ? (
                <Card key={master.id} sx={{ minWidth: 275 }} elevation={0}>
                    <CardHeader
                        sx={{ cursor: "pointer" }}
                        onClick={(e) => addItemCart(master.id)}
                        avatar={
                            <Avatar
                                src={master.avatar}
                                sx={{
                                    width: 60,
                                    height: 60,
                                }}
                            />
                        }
                        action={
                            <IconButton
                                aria-label="settings"
                                onClick={handlerIconClick}
                            >
                                <InfoOutlinedIcon />
                            </IconButton>
                        }
                        title={
                            <Typography variant="button">
                                {master.name}
                            </Typography>
                        }
                        subheader={
                            <List sx={{ padding: 0 }} dense>
                                <ListItem sx={{ padding: 0 }}>
                                    <ListItemText
                                        primary={master.specialization}
                                        secondary={
                                            master.show_rating === 1 ? (
                                                <Rating
                                                    name="read-only"
                                                    value={master.rating}
                                                    size="small"
                                                    readOnly
                                                />
                                            ) : null
                                        }
                                    />
                                </ListItem>
                            </List>
                        }
                    />
                    {/*  ЗДЕСЬ  */}

                    {(sDate ) ? (
                        <TimeChips
                            seances={seance ? seance.seances : []}
                            master={master}
                            fullDate={fullDate}
                            myDate={myDate}
                            addItemCart={addItemCart}
                            loading={sLoading}
                        />
                    ) : null }

                    {/*  >> ЗДЕСЬ <<  */}

                    {/*<CardActions sx={{position: 'relative' , left: '10px'}}>*/}
                    {/*    <Button size="small" color="primary" variant="outlined" sx={{*/}
                    {/*        fontWeight: '400',*/}
                    {/*        borderRadius: 30 ,*/}
                    {/*        textTransform: 'unset',*/}
                    {/*        padding: '2px 10px'*/}
                    {/*    }}>*/}
                    {/*        Выбрать другой день*/}
                    {/*    </Button>*/}
                    {/*</CardActions>*/}
                </Card>
            ) : (
                // ===>  BOOKABLE FALSE START <======
                <Card key={master.id} sx={{ minWidth: 275 }} elevation={0}>
                    <CardHeader
                        avatar={
                            <Avatar
                                src={master.avatar}
                                sx={{
                                    width: 60,
                                    height: 60,
                                    filter: "grayscale(1)",
                                    opacity: "0.8",
                                }}
                            />
                        }
                        title={
                            <Typography variant="button" color="grey">
                                {master.name}
                            </Typography>
                        }
                        subheader={
                            <List sx={{ padding: 0 }} dense>
                                <ListItem sx={{ padding: 0 }}>
                                    <ListItemText
                                        primary={master.specialization}
                                        secondary={
                                            master.show_rating === 1 ? (
                                                <Rating
                                                    name="read-only"
                                                    value={master.rating}
                                                    size="small"
                                                    disabled
                                                />
                                            ) : null
                                        }
                                    />
                                </ListItem>
                            </List>
                        }
                    />
                </Card>
            )}
            {/*// ===>  BOOKABLE FALSE END <======*/}
            <Divider />
        </React.Fragment>
    );
};

export default EmployeeListItem;
