import React from 'react';
import CardHeader from "@mui/material/CardHeader";
import Skeleton from "@mui/material/Skeleton";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

const SkeletonTimetable = () => {
    const elevation = 0;
    return (
        <Card sx={{ minWidth: 350 }} elevation={elevation}>

            <CardHeader
                avatar={
                    <Skeleton animation="wave" variant="circular" width={40} height={40} />
                }

                title= {<Skeleton
                    animation="wave"
                    height={15}
                    width="80%"
                    style={{ marginBottom: 6 }}
                />}
                subheader={ <Skeleton animation="wave" height={10} width="40%" />}
            />
            <CardContent>
                <Grid container spacing={2}>
                    {Array.from(Array(13)).map((_, index) => (
                        <Grid item xs={3} sm={2} md={2} key={index}>
                            <Skeleton variant="rectangular" width="100%" height={35} variant="rounded"/>
                        </Grid>
                    ))}
                </Grid>



            </CardContent>
        </Card>
    );
};

export default SkeletonTimetable;