import Box from "@mui/material/Box";
import {Button} from "@mui/material";
import React from "react";
import {blueGrey} from "@mui/material/colors";

const notifioLink = "https://notifio.ru";

const BottomLinkNotifio = ({pos}) => {
    console.log("pos" , pos);

    const position = pos === undefined ?  "fixed" : pos ;

    const bGrey = blueGrey['A100'];
    return (
        <>
            <Box sx={{ position , bottom:0 , width: "100%" }}>
                <Button
                    href={notifioLink}
                    size="small"
                    sx={{width: '100%'  , textAlign: 'center' , textTransform: 'none' , color: bGrey}} >
                    работает на Notifio
                </Button>
            </Box>
        </>
    );
}

export default BottomLinkNotifio;