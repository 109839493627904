import React from "react";
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';

const ErrorIndicator = (props) => {

const {info} = props;
    return (
        <React.Fragment>
            <Alert severity="error">
                <AlertTitle>Ошибка</AlertTitle>
                {info}
            </Alert>
        </React.Fragment>
        );
}

export default ErrorIndicator;