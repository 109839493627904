const updateEmployeeList = (state , action) => {
    if (state === undefined) {
        return {
            employees: [],
            loading: true,
            error: null ,
            current: null
        };
    }

    switch (action.type) {

        case 'FETCH_EMPLOYEE_REQUEST':
            return {

                employees: [] ,
                loading: true ,
                error: null ,
                current: null
            };
        case 'FETCH_EMPLOYEE_SUCCESS':
            return {

                employees: action.payload ,
                loading: false ,
                error: null ,
                current: state.employeeList.current

            };
        case 'FETCH_EMPLOYEE_FAILURE':
            return {

                employees: [],
                loading: false ,
                error: action.payload ,
                current: null

            };

        case 'SET_CURRENT_EID':
            // console.log('SET_CURRENT_EID_SEANCE' , action.payload);
            return{
                employees: state.employeeList.employees,
                loading: state.employeeList.loading ,
                error: state.employeeList.error ,
                current: action.payload
            };

        default:
            return state.employeeList;
    }

}

export default updateEmployeeList;