import React from "react";
import Timetable from '../timetable/timetable';



const TimetablePage = () => {

    return(
        <Timetable  />
    );
}

export default TimetablePage;