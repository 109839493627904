import React from 'react';
import ReactDOM from 'react-dom/client';
import {Provider} from 'react-redux';
import {BrowserRouter as Router} from "react-router-dom";

import App from './components/app/app';
import ErrorBoundry from "./components/error-boundry";
import ClientstoreService from "./services/clientstore-service";
import {ClientstoreServiceProvider} from "./components/clientstore-service-context";

import store from './store';



//import './css/bootstrap/bootstrap-extended.min.css';
//import './css/bootstrap/colors.css';
//import './css/bootstrap/components.css';

const clientstoreService = new ClientstoreService();

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
    <Provider store={store}>
        <ErrorBoundry>
            <ClientstoreServiceProvider value={clientstoreService} >
                <Router>
                    <App />
                </Router>
            </ClientstoreServiceProvider>
        </ErrorBoundry>
    </Provider>
);

