import {object} from "prop-types";
import {setCurrentEmployeeSeance} from "../actions";

const setEmployeeId = (state , seances) => {
    // let newData = [];
    // newData = res.data;
    // console.log('===>' , newData);
    // newData.push({id: eId});

    // return {
    //
    //     seances: action.payload ,
    //     loading: false ,
    //     error: null ,
    //     current: null
    //
    // };
}

const concatSeances = (oldItem, newItem)  => {
    return {
        ...oldItem , ...newItem
    }
}

const updateSeancesList = (state , action) => {
    if (state === undefined) {
        return {
            seances: [],
            loading: true,
            error: null ,
            current: null
        };
    }

    switch (action.type) {

        case 'FETCH_SEANCES_REQUEST':
            return {
                seances: [] ,
                loading: true ,
                error: null ,
                current: null
            };
        case 'FETCH_SEANCES_SUCCESS':

            let oldSeance = state.seancesList.seances ;
            let newSeance = {};
                newSeance[action.payload.id] = action.payload;

            return {

                seances: concatSeances(oldSeance , newSeance) ,
                loading: false ,
                error: null ,
                current: state.seancesList.current

            };
        case 'FETCH_SEANCES_FAILURE':
            return {

                seances: [],
                loading: false ,
                error: action.payload ,
                current: null

            };
        case 'SET_CURRENT_EID_SEANCE':
           // console.log('SET_CURRENT_EID_SEANCE' , action.payload);
            return{
                seances: state.seancesList.seances,
                loading: state.seancesList.loading ,
                error: state.seancesList.error ,
                current: action.payload
            };

        default:
            return state.seancesList;
    }

}

export default updateSeancesList;