import React from "react";
import MoveRecord from "../records/move-record";


const MoveRecordPage = () => {

    return(
        <React.Fragment>

            <MoveRecord  />
        </React.Fragment>
    );
}

export default MoveRecordPage;