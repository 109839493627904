import React from "react";
import ServiceList from "../service-list";

const SelectServicePage = () => {
    return(
        <ServiceList />

    );
}

export default SelectServicePage;