import React from 'react';
import ListItemButton from "@mui/material/ListItemButton";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import {ListItemIcon} from "@mui/material";
import Avatar from "@mui/material/Avatar";
import ListItemText from "@mui/material/ListItemText";
import { Link } from "react-router-dom";
import DriveFileRenameOutlineOutlinedIcon from '@mui/icons-material/DriveFileRenameOutlineOutlined';
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { green, pink , blue } from '@mui/material/colors';
// ICONS

import Icon from "@mui/material/Icon";
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import {
    ThemeProvider,
    createTheme,
    experimental_sx as sx,
} from '@mui/material/styles';
import IconButton from "@mui/material/IconButton";

const ShowInfoBlock = ({info}) => {
    if(info === null) return info;
    if(Array.isArray(info) && info.length > 0 ){

        return (
            <ul>
                {
                    info.map((el) => (

                        <li key={el.id}>{el.title}</li>

                    ))
                }

            </ul>
        );

    }
}

const _basePath = '/';
// <CalendarMonthIcon  sx={{color:'#1565c0'}}  />



const ElementListItem = ({element , delSeanceDate}) => {
    //console.log(element);
    /*
    title
    info
    subinfo
    icon
    avatar: {type [icon ,  avatar] , src }
    * */
    const handleClearSeanseDate = () => {
        delSeanceDate();
    }
    return (
        <React.Fragment>

            {
                element.button  ?
                    <React.Fragment>
                    <ListItemIcon>
                        <Icon >{element.icon}</Icon>
                    </ListItemIcon>
                    <ListItemText sx={{color:"black"}} primary={ <Typography >{element.title}</Typography>} secondary={element.info} />
                    </React.Fragment>
                        :
                    <React.Fragment>
                        <ListItemAvatar>
                            {
                                element.avatar.type === 'avatar' ?
                                    <Avatar alt={element.info} src={element.avatar.src} sx={{ width: 40, height: 40 }} />
                                    : element.avatar.type === 'icon' ?
                                        <ListItemIcon>
                                            <Icon sx={{color:'#1565c0'}}>{element.icon}</Icon>
                                        </ListItemIcon>
                                    : null
                            }
                        </ListItemAvatar>
                        <ListItemText sx={{color:"black"}} primary={ <Typography >{element.info}</Typography>}
                                      secondary={<Typography variant="caption" sx={{color: 'grey'}}>{element.subinfo}</Typography>} />
                    </React.Fragment>

            }

            {(typeof element.btn_callback === 'function' && element.button) &&
                <Button variant="contained" size="small" onClick={() => element.btn_callback(element.id)} sx={{
                    fontWeight: '400',
                    borderRadius: 30 ,
                    textTransform: 'unset',
                    padding: '2px 10px' ,
                    cursor: 'pointer'
                }}>Выбрать</Button>
            }

            {(element.button && typeof element.btn_callback !== 'function') &&
                <Link to={element.id} style={{textDecoration: 'none'}}>
                    <Button variant="contained" size="small" sx={{
                        fontWeight: '400',
                        borderRadius: 30,
                        textTransform: 'unset',
                        padding: '2px 10px',
                        cursor: 'pointer'
                    }}>Выбрать</Button>
                </Link>
            }
            {/* кнопка Изменить показывается на экране у всех кроме выбора даты, если button=false и btn_disabled false
              btn_disabled =true знаичт что идет перенос записи
              */}
            {(!element.button && element.id !== '/timetable' && !element.btn_disabled) &&
                <Link to={element.id} style={{textDecoration :'none' }} >
                    <Button variant="outlined" color="primary" size="small" sx={{
                        fontWeight: '400',
                        borderRadius: 30 ,
                        textTransform: 'unset',
                        padding: '2px 10px' ,
                        cursor: 'pointer'
                    }}> Изменить</Button>
                </Link>
            }

            {(!element.button && element.id === '/timetable') &&

                <IconButton onClick={handleClearSeanseDate} >
                    <DeleteForeverOutlinedIcon />
                </IconButton>
                // <Link to={element.id} style={{textDecoration :'none' }}>
                //     <Button variant="outlined" color="primary" size="small" sx={{
                //         fontWeight: '400',
                //         borderRadius: 30 ,
                //         textTransform: 'unset',
                //         padding: '2px 10px' ,
                //         cursor: 'pointer'
                //     }}>Изменить</Button>
                // </Link>
            }



            {/*<Link to={element.id} style={{textDecoration :'none' }}>*/}
            {/*    {*/}
            {/*        (element.button)?*/}

            {/*    <Button variant="contained" size="small" sx={{*/}
            {/*        fontWeight: '400',*/}
            {/*        borderRadius: 30 ,*/}
            {/*        textTransform: 'unset',*/}
            {/*        padding: '2px 10px' ,*/}
            {/*        cursor: 'pointer'*/}
            {/*    }}>Выбрать</Button> :*/}
            {/*            <Button variant="outlined" color="primary" size="small" sx={{*/}
            {/*                fontWeight: '400',*/}
            {/*                borderRadius: 30 ,*/}
            {/*                textTransform: 'unset',*/}
            {/*                padding: '2px 10px' ,*/}
            {/*                cursor: 'pointer'*/}
            {/*            }}>Изменить</Button>*/}

            {/*    }*/}
            {/*</Link>*/}

        </React.Fragment>

    );
};

export default ElementListItem;