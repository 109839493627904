const isObjEmpty = (obj) => {
    for (var i in obj) {
        if (obj.hasOwnProperty(i)) {
            return false;
        }
    }
    return true;
}

export const isObject = (obj , key) => {
    if(Object.prototype.toString.call(obj) === '[object Object]') {
        return true;
    }
    return false;
}

export default isObjEmpty;

