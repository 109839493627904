import React, {useCallback, useEffect, useState} from 'react';
import List from "@mui/material/List";
import ElementListItem from "../element-list-item";
import ListItem from "@mui/material/ListItem";
import Icon from '@mui/material/Icon';
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";

import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import StarBorder from '@mui/icons-material/StarBorder';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import Typography from "@mui/material/Typography";
import CancelIcon from '@mui/icons-material/Cancel';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import {
    fetchBookform,
    setCurrentFormId,
    setCurrentCompId,
    setCurrentCityId,
    bookformLoaded,
    serviceCartClear
} from "../../actions";
import {withClientstoreService} from "../hoc";
import {connect} from "react-redux";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import TopBar from "./TopBar";




const CompaniesDisplay = (props) => {

    const {formId , bookform:{ bookform ,form_id , city_id , comp_id , loading , error  } ,
        setFormID  , setCityID , setCompID ,
        getBookForm , setBookform , resetCity } = props;

    // console.log("props" , props);

    let [arRender , setRender ] = useState([]);
    let [curCity , setCurCity]= useState({});
    let [arCompanies , setArCompanies] = useState([]);

    const companies = bookform.data.companies ;



    const getFilialsList = useCallback((cityId) => {
       // const arRes = companies.filter(el => el.city_id === cityId);
        const arRes = [];
        companies.forEach(el => {
            let obj = {};
            if(el.city_id === cityId){
                obj = {title: el.title ,
                    info: `${el.city},${el.address} , ${el.schedule}` ,
                    subinfo: '${el.schedule}' ,
                    icon: 'add_task',
                    avatar: {type: 'avatar' , src: el.logo} ,
                    button: true ,  btn_callback: handleCompanies ,
                    id: el.company_id

                };
                arRes.push(obj);
            }
        });
        setRender(arRes);
        // return arRes;
    } , [companies] );

    const getCityByID = (city_id) => {
        const res = companies.filter(el => el.city_id === city_id);
        setCurCity(res[0]);
    }


    const getCityList = useCallback(() => {
        let objCity = [];
        companies.forEach(el => {
            const index = objCity.findIndex(item => item.city === el.city);

            let obj = {};

            // title
            // info
            // subinfo
            // icon
            // avatar: {type [icon ,  avatar] , src }
            if(index === -1){
                obj = {id: el.city_id  , city: el.city , count: 1 , city_id: el.city_id ,
                    title: `${el.city} (${el.country})` ,
                    info: `Филиалов: ${1}` ,
                    subinfo: '' ,
                    icon: 'pin_drop',
                    avatar: {type: 'icon' , src: ''} ,
                    button: true ,
                    btn_callback: callbackButton ,
                };
            }else if(index > -1){
                const cnt = objCity[index].count;
                // console.log("dubl" , objCity[index]);
                objCity.splice(index , 1);
                obj = {id: el.city_id ,  city: el.city , count: cnt+1 , city_id: el.city_id ,
                    title: `${el.city} (${el.country})` ,
                    info: `Филиалов: ${1}` ,
                    subinfo: '' ,
                    icon: 'add_task',
                    avatar: {type: 'icon' , src: ''} ,
                    button: true ,  btn_callback: callbackButton ,
                };
            }
            objCity = [...objCity , obj];

        });
        // console.log("arCity" , objCity);
        setRender(objCity);
    } , [companies]) ;

    // useEffect(() => {
    //     getCityByID(city_id);
    // } , []);
    useEffect(() => {
        // console.log("[] getCityList()");
        getCityList();
        // если филиалы в одном городе

        if(city_id > 0){
            getCityByID(city_id);
            // console.log("[city_id,comp_id] getFilialsList");
            getFilialsList(city_id);
            // console.log("arRes" , arCompanies);
        }
    } , [city_id,comp_id ]);

    useEffect(() => {
        let objCity = [];
        companies.forEach(el => {
            const index = objCity.findIndex(item => item.city === el.city);
            let obj = {};
            obj = {id: el.city_id  , city: el.city }
            objCity = [...objCity , obj];
        });

        if(objCity.length === 1){
            callbackButton(objCity[0].id)
        }
    }, [companies , callbackButton]);



    const getInfo = (arInfo , id) => {
        if(id > 0){
            const filialInfo = arInfo.filter(el => el.company_id === id);

            return filialInfo;
        }
        return [];
    }
    const handleCompanies = (cid) => {
       // console.log("CID" , cid);
        if(cid > 0){

            let new_data = bookform.data ;
            new_data = {...new_data , company_id: cid}
            // console.log("new_data", new_data);
            let newBookform = bookform;
            newBookform = {...newBookform , data: new_data};
           // console.log("new_data", newBookform);
            const cInfo = getInfo(companies , cid);
            setCompID({id: cid , info: cInfo[0]});
            setBookform(newBookform);


        }
    }

    const callbackButton = (id) => {
       // console.log("Select" , id);
        if(id > 0){
            setCityID(id);
            getFilialsList(city_id);
        }
    }


    return (
        <>
            {(city_id > 0 && curCity.hasOwnProperty("city")) &&
                <>
                    <TopBar filial={true} data={
                        {title: curCity.city ,
                            subheader: curCity.country ,
                            logo:  null ,
                        }
                    }
                        handleReset={()=> resetCity()} />
                    <FilialsList filial={arRender} />
                </>

            }
            {city_id === 0 &&
                <CompaniesList filial={arRender} />
            }
        </>
    );
};

const FilialsList = (props) => {
    const {filial} = props;
    return(
        <Box>
            <List>
                {
                    filial.map((element) => {

                        return(
                            <React.Fragment>
                                <ListItem  key={element.id} >
                                    <ListItemAvatar>
                                        {
                                            element.avatar.type === 'avatar' ?
                                                <Avatar alt={element.info} src={element.avatar.src} sx={{ width: 40, height: 40 }} />
                                                : element.avatar.type === 'icon' ?
                                                    <ListItemIcon>
                                                        <Icon sx={{color:'#1565c0'}}>{element.icon}</Icon>
                                                    </ListItemIcon>
                                                    : null
                                        }
                                    </ListItemAvatar>
                                    <ListItemText sx={{color:"black"}} primary={ <Typography >{element.title}</Typography>}
                                                  secondary={<Typography variant="caption" sx={{color: 'grey'}}>{element.info}</Typography>} />
                                    <Button variant="contained" size="small" onClick={() => element.btn_callback(element.id)} sx={{
                                        fontWeight: '400',
                                        borderRadius: 30 ,
                                        textTransform: 'unset',
                                        padding: '2px 10px' ,
                                        cursor: 'pointer'
                                    }}>Выбрать</Button>


                                </ListItem>

                                <Divider />
                            </React.Fragment>
                        )
                    })
                }
            </List>
        </Box>
    );
}

const CompaniesList = (props) => {
    const {filial} = props;

    return(
        <Box>
            <List>
                {
                    filial.map((element) => {

                        return(
                            <React.Fragment>
                                <ListItem  key={element.id} >
                                    <ElementListItem element={element}  />
                                </ListItem>

                                <Divider />
                            </React.Fragment>
                        )
                    })
                }
            </List>
        </Box>
    );
}



const mapStateToProps = (state) => {
    const {bookform  , loading , error } = state;
    return {
        bookform ,
        success: bookform.success ,
        loading ,
        error ,
        form_id: bookform.form_id ,
        city_id: bookform.city_id ,
        comp_id: bookform.comp_id ,


    }
};
const mapDispatchToProps = (dispatch , {clientstoreService}) => {

    //const  = ownProps;
    return {
        getBookForm : fetchBookform(clientstoreService , dispatch) ,
        setFormID: (form_id) => dispatch(setCurrentFormId(form_id)) ,
        setCityID: (city_id) => dispatch(setCurrentCityId(city_id)) ,
        setCompID: (comp_id) => dispatch(setCurrentCompId(comp_id)) ,
        setBookform: (bookform) => dispatch(bookformLoaded(bookform)),

    }

}

export default withClientstoreService()(connect(mapStateToProps , mapDispatchToProps)(CompaniesDisplay));