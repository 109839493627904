import React, {useState} from "react";
import '../../css/app-css/app-todo.min.css'
import AddBoxIcon from '@mui/icons-material/AddBox';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import ListItemText from "@mui/material/ListItemText";
import ListItemButton from '@mui/material/ListItemButton';
import Typography from "@mui/material/Typography";
import {
    ThemeProvider,
    createTheme,
    experimental_sx as sx,
} from '@mui/material/styles';
import {Accordion, AccordionDetails, AccordionSummary, ListItem, Stack} from "@mui/material";
import Chip from "@mui/material/Chip";
import CustomizedAccordions from "../service-list/CustomizedAccordion";
import FullScreenDialog from "../service-list/FullScreenDialog";

const defaultTheme = createTheme();
const theme = createTheme({
    components: {
        // Name of the component ⚛️
        MuiListItemButton: {
            styleOverrides: {
                root: sx({
                    '&.Mui-selected':{

                        borderLeft: `6px solid #1565c0`

                    }
                })
            },
        },
    },
});


const ServiceListItem = ({ check ,  service , onAddedToCart}) => {
    const [dialogOpen, setDialogOpen] = React.useState(false);
    const { id ,
        title ,
        price_min ,
        price_max ,
        discount ,
        comment ,
        weight ,
        active ,
        sex ,
        image ,
        prepaid ,
        seance_length ,
        abonement_restriction
       } = service;

    const handleClickOpen = (e) => {
        setDialogOpen(true);
    };

    const handleClickCart = (e) => {
     //  console.log("CLICK" , dialogOpen);
     //    e.stopPropagation();
     //    if(dialogOpen === false){
           onAddedToCart();
      // }
    }

    const handleClose = () => {
        setDialogOpen(false);
    };


    if(service.active === 0)return (
        <ThemeProvider theme={theme}>
            <ListItemButton role={undefined} >
                <ListItemAvatar>
                    <Avatar alt={title} src={image} variant="rounded" />
                </ListItemAvatar>
                <ListItemText sx={{color:"grey"}} primary={
                    <React.Fragment>
                        <Typography >{title}</Typography>
                    </React.Fragment>
                } secondary={price_min} />

            </ListItemButton>
        </ThemeProvider>
    );

    return (
        <ThemeProvider theme={theme}>
        <ListItem role={undefined}   dense selected={check}>
            {!!image === true &&
                <ListItemAvatar onClick={handleClickCart}>
                    <Avatar src={image} variant="rounded" sx={{ width: 50, height: 50 }}/>
                </ListItemAvatar>
            }

                <ListItemText sx={{color:"black"}}
                              primary={
                                    <React.Fragment>
                                        <Stack spacing={0.5}>
                                            {prepaid === 'required' &&
                                                <Chip label="Предоплата" color="success" variant="outlined" size="small"  sx={{width:'45%'}} />
                                            }
                                             <Typography onClick={handleClickCart}>{title}</Typography>
                                        </Stack>
                                    </React.Fragment>
                              }
                              secondary={
                                        // `${preInfo}...`
                                  comment.length > 0 &&
                                  <FullScreenDialog
                                      open={dialogOpen}
                                      cover={image}
                                      title={title}
                                      comment={comment}
                                      price={price_min}
                                      fnOpen={handleClickOpen}
                                      fnClose={handleClose}

                                  />
                                   }
                />
            <Typography variant="button"  sx={{pr:0.5 , pl: 0.5 , color: 'green'}}>{`${price_max===0?price_min:price_max}₽`}</Typography>
            {(check) ? <IndeterminateCheckBoxIcon onClick={handleClickCart} /> : <AddBoxIcon onClick={handleClickCart} color="primary"/>}

        </ListItem>
        </ThemeProvider>



    );
}
export default ServiceListItem;