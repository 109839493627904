import React from "react";


const {
    Provider: ClientstoreServiceProvider ,
    Consumer: ClientstoreServiceConsumer
} = React.createContext();

export {
    ClientstoreServiceProvider ,
    ClientstoreServiceConsumer
}