const updateBookRecord = (state , action) => {
    if (state === undefined) {
        return {
            bookrecord: [],
            loading: false,
            error: null

        };
    }

    switch (action.type) {

        case 'FETCH_BOOKRECORD_REQUEST':
            return {

                bookrecord: [] ,
                loading: true ,
                error: null ,

            };
        case 'FETCH_BOOKRECORD_SUCCESS':
            return {
                bookrecord: action.payload ,
                loading: false ,
                error: null ,


            };
        case 'FETCH_BOOKRECORD_FAILURE':
            return {
                bookrecord: [],
                loading: false ,
                error: action.payload ,


            };
        default:
            return state.bookrecord;
    }

}

export default updateBookRecord;