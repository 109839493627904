const updateBookdatesList = (state , action) => {
    if (state === undefined) {
        return {
            bookdates: [],
            loading: true,
            error: null ,
            current: ''
        };
    }

    switch (action.type) {

        case 'SET_CURRENT_BOOKDATE':
            return{
                bookdates: state.bookdatesList.bookdates ,
                loading: state.bookdatesList.loading ,
                error: state.bookdatesList.error ,
                current: action.payload
            };


        case 'FETCH_BOOKDATES_REQUEST':
            return {

                bookdates: [] ,
                loading: true ,
                error: null ,
                current: state.bookdatesList.current
            };
        case 'FETCH_BOOKDATES_SUCCESS':
            return {

                bookdates: action.payload ,
                loading: false ,
                error: null ,
                current: state.bookdatesList.current

            };

        case 'FETCH_BOOKDATES_FAILURE':
            return {

                bookdates: [],
                loading: false ,
                error: action.payload,
                current: state.bookdatesList.current

            };

        default:
            return state.bookdatesList;
    }

}

export default updateBookdatesList;