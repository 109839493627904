const updateSmsCode = (state , action) => {
    if (state === undefined) {
        return {
            // phone: null,
            // code: null ,
            type: null ,
            success: false,
            loading: false,
            error: null

        };
    }

    switch (action.type) {

        case 'FETCH_SMSCODE_REQUEST':
            return {
                type: 'request' ,
                success: false ,
                loading: true ,
                error: null ,

            };
        case 'FETCH_SMSCODE_SENDED':
            return {
                success: false ,
                type: 'send' ,
                loading: false ,
                error: null ,


            };
        case 'FETCH_SMSCODE_SUCCESS':
            return {
                success: true ,
                type: 'ok' ,
                loading: false ,
                error: null ,
            };

        case 'FETCH_SMSCODE_FAILURE':
            return {
                type: 'error' ,
                success: false,
                loading: false ,
                error: action.payload ,


            };
        default:
            return state.smscode;
    }

}

export default updateSmsCode;