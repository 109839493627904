import React , {useEffect} from 'react';
import {connect} from "react-redux";
import {setTgUserData} from "../../actions";

const TgUserData = ({user , setUserData , tguserdata}) => {

    // tg.initDataUnsafe.user.id // уникальный идентификатор пользователя
    // tg.initDataUnsafe.user.isBot // бот ли пользователь (true/false)
    // tg.initDataUnsafe.user.first_name // имя пользователя
    // tg.initDataUnsafe.user.last_name // "фамилия" пользователя
    // tg.initDataUnsafe.user.username // username пользователя
    // tg.initDataUnsafe.user.language_code // код языка пользователя

    if(user !== undefined){
       // console.log(user);
        setUserData(user);
    }
 return null;
//     return (
//         <div>
//             {JSON.stringify(tguserdata)}
//         </div>
//     );
};

const mapStateToProps = (state) => {
    // console.log('mapStateToProps' , state);
    const { tguserdata  } = state;
    return {
        tguserdata
    }
}

const mapDispatchToProps = (dispatch , {clientstoreService}) => {

    //const  = ownProps;
    return {
        setUserData : (fields) => dispatch(setTgUserData(fields))
    }
}



export default connect(mapStateToProps , mapDispatchToProps)(TgUserData);