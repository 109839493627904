import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';

import ServiceList from '../service-list';
import FirstDisplay from '../first-display';
import NavHeader from '../nav-header/nav-header';
import CircularProgress from '@mui/material/CircularProgress';
import Bookform from '../bookform';
import SkeletonMainPage from '../skeletons/SkeletonMainPage';
import CompaniesDisplay from '../companies-display/CompaniesDisplay';
import { bookformLoaded, fetchBookform, serviceCartClear, setCurrentCityId, setCurrentCompId, setCurrentFormId } from '../../actions';
import { withClientstoreService } from '../hoc';

import { Avatar, Button, Card, CardHeader, Divider, IconButton } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import TopBar from '../companies-display/TopBar';
import isObjEmpty from "../../utils/empty-object";

import Box from "@mui/material/Box";
import BottomLinkNotifio from "../bottom-link-notifio";



const MainPage = (props) => {
    const {
        filials,
       // bookform ,
        bookform: {
            bookform: {
                data: {
                   // companies ,
                    setttings
                },
            },
            action ,
            form_id,
            city_id,
            comp_id,
            infoComp,
            loading,
            error,
        },
        setCompID,
        setCityID,
        serviceCartClear,
    } = props;

    const { filial, setValFilial } = useState([]);

    // console.log("FILIALS" , filials);
    // console.log("COMP_ID" , comp_id);
     // console.log("setttings" , bookform);

    // ===== убираем баг =====
    // полуаем bool loading из компонента и если идет загрузка bookform здесь выкидываем колесо крутящиеся
    // const [bookFormLoading , setBookFormLoading ] = useState(true);
    // const handleLoading = (load) => {
    //     setBookFormLoading(load);
    //    // console.log("=>>> " , bookFormLoading);
    // }

    // =======================

    const handleResetCompID = () => {
        setCompID({ id: 0, info: [] });
        serviceCartClear();
    };

    const handleResetCityID = () => {
        setCityID(0);
        serviceCartClear();
    };
    // if(bookFormLoading){
    //     return <CircularProgress />
    // }

    const navigate = useNavigate();
    let isRedirect = false;
    if (useLocation().state) {
        isRedirect = useLocation().state.isRedirect;
    }
    // const record_type_value = useSelector((state) => state.bookform.bookform.data.setttings.record_type);

    const record_type_value = isObjEmpty(setttings)  ?  1 : setttings.record_type   ;
    useEffect(() => {
        if (isRedirect) {
            return;
        }

        // если перенос записи

        if(action !== 'book'){
            return navigate('/');
        }

        if (record_type_value === 0) {
            return navigate('/select-record-type');
        }
        if (record_type_value === 1) {
            return navigate('/');
        }
        if (record_type_value === 2) {
            return navigate('/form-groups');
        }
    }, []);

    return (
        <>
            {/*<Bookform formId={formId} getLoading={handleLoading} />*/}
            {/*{bookFormLoading && <SkeletonMainPage />}*/}
            {/*{!bookFormLoading &&  <FirstDisplay  />}*/}
            {filials && comp_id === 0 && <CompaniesDisplay resetCity={handleResetCityID} />}


            {filials && comp_id > 0 && (
                <>
                    <TopBar
                        filial
                        data={{
                            title: infoComp.title,
                            logo: infoComp.logo,
                            subheader: infoComp.address,
                        }}
                        handleReset={handleResetCompID}
                    />

                    <FirstDisplay />
                </>
            )}
            {!filials && <FirstDisplay />}
            <BottomLinkNotifio />
        </>
    );
};

const mapStateToProps = (state) => {
    const { bookform, loading, error } = state;
    return {
        bookform,
        success: bookform.success,
        loading,
        error,
        form_id: bookform.form_id,
        city_id: bookform.city_id,
        comp_id: bookform.comp_id,
        infoComp: bookform.infoComp,
    };
};
const mapDispatchToProps = (dispatch, { clientstoreService }) => {
    //const  = ownProps;
    return {
        getBookForm: fetchBookform(clientstoreService, dispatch),
        setFormID: (form_id) => dispatch(setCurrentFormId(form_id)),
        setCityID: (city_id) => dispatch(setCurrentCityId(city_id)),
        setCompID: (comp_id) => dispatch(setCurrentCompId(comp_id)),
        setBookform: (bookform) => dispatch(bookformLoaded(bookform)),
        serviceCartClear: () => dispatch(serviceCartClear()),
    };
};

export default withClientstoreService()(connect(mapStateToProps, mapDispatchToProps)(MainPage));
