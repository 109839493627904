import React, {Component} from "react";
import ErrorIndicator from "../error-indicator";

class ErrorBoundry extends Component {

    state = {
        hasError : false ,
        error : null
    }

    componentDidCatch(error, errorInfo) {
        //console.log(error);

        // для тестирования
        // const url = "https://api.notifio.ru/mylogs";
        const url = "https://functions.yandexcloud.net/d4euujo1atjpu240nrqt";
        console.log(error);
        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: error,
        };
        fetch(url, requestOptions)
            .then((response) => response.json())
            .catch((error) => {
                console.log("There was an error.", error);
            });

        this.setState({
            hasError: true ,
            error: error});
    }

    render(){
//console.log("Erorr" , this.state.error );
            if(this.state.hasError){
                return <ErrorIndicator info={`${this.state.error}`}/>
            }
            return this.props.children;
    }
}

export default ErrorBoundry;