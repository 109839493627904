import React, {useEffect , useLayoutEffect , useState} from 'react';
import Box from '@mui/material/Box';
import { styled, alpha } from '@mui/material/styles';
import SearchIcon from "@mui/icons-material/Search";
import BackspaceOutlinedIcon from '@mui/icons-material/BackspaceOutlined';
import CancelIcon from '@mui/icons-material/Cancel';
import {setCurrentTerm, updateEmployeeListByTerm} from "../../actions";
import {connect} from "react-redux";
import { useLocation } from "react-router";

import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';

const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    // borderRadius: theme.shape.borderRadius,
    borderRadius: '10px' ,
    backgroundColor: alpha(theme.palette.grey[700], 0.15),
    '&:hover': {
        backgroundColor: alpha(theme.palette.grey[700], 0.25),
    },

    width: '100%',
    [theme.breakpoints.up('xs')]: {
        width: 'auto',
    },
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {

        padding: '6px',
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('xs')]: {
            width: '12ch',
            '&:focus': {
                width: '20ch',
            },
        },
    },
}));

const hideLocation = ['/timetable', '/form-groups' , '/yourphone' , '/moverecord', '/form-groups/info'];


const SearchPanel = ({setCurrentTerm , update , updateList , term}) => {

    // const [term, setTerm] = React.useState('');
    const location = useLocation();
    const [searchButton , setValue] = useState(true);

    useEffect(() => {
        //console.log("SEARCH PANEL TERM" , term);

       setCurrentTerm('');
       updateList(!update);
       setValue(true);


    } , [location]);


    const handleChange = (event) => {
        //setTerm(event.target.value);
        const txt = event.target.value;
        //updateList(false);
       // console.log(txt.length);
       // (txt.length === 0 )?updateList(true):updateList(false);
        setCurrentTerm(txt);
        if(!searchButton && txt.length === 0){
            actionSearchPanel();
        }
    };

    const actionSearchPanel = () => {
        if(term.length > 0 ){
            setValue(!searchButton);
        }

        if(!searchButton){
            setCurrentTerm('');
            setValue(!searchButton);
            updateList(!update)
        }

        if(term.length > 0 ) updateList(!update);
    }

    const handleKeyDown = (event) => {
       // console.log('User pressed: ', event.key);
        if(event.key === 'Enter')actionSearchPanel();
    }

    const handleClick = (e) => {
        //console.log(`CLICK length ${term.length} button ${searchButton}`);
        actionSearchPanel();
    }

    if(hideLocation.indexOf(location.pathname)  > -1)
    {
        return null;
    }

    return(
        <Search>
        {/* <Paper*/}
        {/*    component="form"*/}
        {/*    elevation={0}*/}
        {/*    sx={{ display: 'flex', alignItems: 'center'}}*/}
        {/*>*/}

            <StyledInputBase

                placeholder="Найти"
                inputProps={{ 'aria-label': 'Найти...' }}
                value={term}
                id="search-panel"
                onChange={handleChange}
                onKeyDown={handleKeyDown}

            />


            <IconButton type="button" sx={{ padding: '6px' , marginRight: '1px' }} aria-label="Найти" onClick={()=> handleClick() }>
                {(searchButton)?<SearchIcon fontSize="small" sx={{ color: 'rgba(255, 255, 255)' }} /> : <CancelIcon fontSize="small" sx={{ color: 'rgba(255, 255, 255, 0.75)' }}/> }
            </IconButton>
        {/*</Paper>*/}
        </Search>
    );

}

const mapStateToProps = (state) => {
    // console.log('mapStateToProps' , state);
    const { term : {term , update}  } = state;
    return {
        term ,
        update
    }
}

const mapDispatchToProps = (dispatch) => {

    //const  = ownProps;
    return {
        setCurrentTerm : (term) => dispatch(setCurrentTerm(term)) ,
        updateList : (bool) => dispatch(updateEmployeeListByTerm(bool))
    }

}

export default connect(mapStateToProps , mapDispatchToProps)(SearchPanel);