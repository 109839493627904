import React from "react";
import FormPhone from "../form-phone";




const FormPhonePage = () => {

    return(
        <React.Fragment>
            <FormPhone  />
        </React.Fragment>
    );
}

export default FormPhonePage;