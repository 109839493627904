import React from "react";
import EmployeeList from '../employee-list';


const SelectEmployeePage = () => {

    return(
            <EmployeeList  />
    );
}

export default SelectEmployeePage;