const updateServiceList = (state , action) => {
    if (state === undefined) {
        let services = [];
        // if (localStorage.getItem("servicesList.services")) {
        //     const timeLimit = JSON.parse(localStorage.getItem("servicesList.services"))[1];
        //     if (timeLimit >= +new Date()) {
        //         services = JSON.parse(localStorage.getItem("servicesList.services"))[0];
        //     }
        // }

        return {
            services: services,
            loading: true,
            error: null
        };
    }

    switch (action.type) {

        case 'FETCH_SERVICE_REQUEST':
           // console.log("FETCH_SERVICE_REQUEST" , state.servicesList.services);
            if(state.servicesList.services.length === 0){
                return {
                    services: [] ,
                    loading: true ,
                    error: null
                }
            }
            return {
                services: state.servicesList.services ,
                loading: false ,
                error: null
            };
        case 'FETCH_SERVICE_SUCCESS':
            // console.log("FETCH_SERVICE_SUCCESS" , state.servicesList.services);
            return {

                services: action.payload ,
                loading: false ,
                error: null

            };
        case 'FETCH_SERVICE_FAILURE':
            return {

                services: [],
                loading: false ,
                error: action.payload

            };

        default:
            return state.servicesList;
    }

}

export default updateServiceList;