const updateSearchTerm = (state, action) => {


    if (state === undefined) {
        return {
            term: '',
            update: true
        };
    }

    switch(action.type) {

        case 'SET_CURRENT_TERM':
            return {
                term: action.payload ,
                update: state.term.update
            };

        case 'UPDATE_EMPLOYEE_LIST':
            return {
                term: state.term.term ,
                update: action.payload
            };


        default:
            return state.term;
    }
};

export default updateSearchTerm;