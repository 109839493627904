import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import {CardActions, CardContent, CardMedia} from "@mui/material";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function FullScreenDialog({title , comment , cover , price , open , fnOpen , fnClose}) {
   // const [open, setOpen] = React.useState(false);

    let preInfo = "";
    if(comment.length > 0 ){
        preInfo = comment.length > 47 ? comment.substring(0,46) : comment;
    }





    return (
        <div>
            <Typography variant="body2" > {`${preInfo}...`}
                <span onClick={fnOpen} > <strong>ещё</strong></span>
            </Typography>

            <Dialog
                fullScreen
                open={open}
                onClose={fnClose}
                TransitionComponent={Transition}
            >
                <AppBar sx={{ position: 'relative' }}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={fnClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                        {/*<Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">*/}
                        {/*    Закрыть*/}
                        {/*</Typography>*/}
                        <Button autoFocus color="inherit" onClick={fnClose}>
                            Закрыть
                        </Button>
                    </Toolbar>
                </AppBar>
                <Box sx={{p:1}}>
                    <Card>
                        <CardMedia
                            sx={{ height: 140 }}
                            image={cover}
                            title="green iguana"
                        />
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="div">
                                {title}
                            </Typography>
                            <Typography sx={{ mb: 1.5 }} color="text.secondary">
                                {price}₽
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                {comment}
                            </Typography>
                        </CardContent>
                        <CardActions>
                            <Button size="small" onClick={fnClose}>Вернуться к выбору</Button>
                        </CardActions>

                    </Card>
                </Box>

            </Dialog>
        </div>
    );
}