const updateBookform = (state , action) => {
    if (state === undefined) {
        return {
            bookform: [],
            loading: false,
            error: null ,
            form_id: 0 ,
            comp_id: 0 ,
            city_id: 0 ,
            infoComp: [] ,
            hiddenMasterStep: false ,
            record_id: 0 ,
            u_phone: null ,
            action: 'book' ,
        };
    }

    switch (action.type) {

        case 'FETCH_BOOKFORM_REQUEST':
            return {

                bookform: [] ,
                loading: true ,
                error: null ,
                form_id: state.bookform.form_id ,
                comp_id: state.bookform.comp_id ,
                city_id: state.bookform.city_id ,
                infoComp: state.bookform.infoComp ,
                hiddenMasterStep: state.bookform.hiddenMasterStep ,
                record_id: state.bookform.record_id ,
                u_phone: state.bookform.u_phone ,
                action: state.bookform.action ,
            };
        case 'FETCH_BOOKFORM_SUCCESS':
            return {
                bookform: action.payload ,
                loading: false ,
                error: null ,
                form_id: state.bookform.form_id ,
                comp_id: state.bookform.comp_id ,
                city_id: state.bookform.city_id ,
                infoComp: state.bookform.infoComp ,
                hiddenMasterStep: state.bookform.hiddenMasterStep ,
                record_id: state.bookform.record_id ,
                u_phone: state.bookform.u_phone ,
                action: state.bookform.action ,

            };
        case 'FETCH_BOOKFORM_FAILURE':
            return {
                bookform: [],
                loading: false ,
                error: action.payload ,
                form_id: state.bookform.form_id ,
                comp_id: state.bookform.comp_id ,
                city_id: state.bookform.city_id ,
                infoComp: state.bookform.infoComp ,
                hiddenMasterStep: state.bookform.hiddenMasterStep ,
                record_id: state.bookform.record_id ,
                u_phone: state.bookform.u_phone ,
                action: state.bookform.action ,

            };
        case 'SET_CURRENT_RECORD_ID':
            return{
                bookform: state.bookform.bookform,
                loading: false ,
                error: null ,
                form_id: state.bookform.form_id ,
                comp_id: state.bookform.comp_id ,
                city_id: state.bookform.city_id ,
                infoComp: state.bookform.infoComp ,
                hiddenMasterStep: state.bookform.hiddenMasterStep ,
                record_id: action.payload.id ,
                u_phone: state.bookform.u_phone ,
                action: action.payload.action ,
            }
        case 'SET_CURRENT_USER_PHONE':
            // console.log("NEW BOOKFORM" , state.bookform);
            return{
                bookform: state.bookform.bookform,
                loading: false ,
                error: null ,
                form_id: state.bookform.form_id ,
                comp_id: state.bookform.comp_id ,
                city_id: state.bookform.city_id ,
                infoComp: state.bookform.infoComp ,
                hiddenMasterStep: state.bookform.hiddenMasterStep ,
                record_id: state.bookform.record_id ,
                u_phone: action.payload ,
                action: state.bookform.action ,
            }
        case 'SET_CURRENT_COMPANY_ID':
            return {
                bookform: state.bookform.bookform,
                loading: false ,
                error: null ,
                form_id: state.bookform.form_id ,
                comp_id: action.payload.id ,
                city_id: state.bookform.city_id ,
                infoComp: action.payload.info ,
                hiddenMasterStep: state.bookform.hiddenMasterStep ,
                record_id: state.bookform.record_id ,
                u_phone: state.bookform.u_phone ,
                action: state.bookform.action ,
            } ;
        case 'SET_CURRENT_CITY_ID':
            return {
                bookform: state.bookform.bookform,
                loading: false ,
                error: null ,
                form_id: state.bookform.form_id ,
                comp_id: state.bookform.comp_id ,
                city_id: action.payload ,
                infoComp: state.bookform.infoComp ,
                hiddenMasterStep: state.bookform.hiddenMasterStep ,
                record_id: state.bookform.record_id ,
                u_phone: state.bookform.u_phone ,
                action: state.bookform.action ,
            }

        case 'SET_CURRENT_FORM_ID':
            return {
                bookform: state.bookform.bookform,
                loading: false ,
                error: null ,
                form_id: action.payload ,
                comp_id: state.bookform.comp_id ,
                city_id: state.bookform.city_id ,
                infoComp: state.bookform.infoComp ,
                hiddenMasterStep: state.bookform.hiddenMasterStep ,
                record_id: state.bookform.record_id ,
                u_phone: state.bookform.u_phone ,
                action: state.bookform.action ,
            }

        case 'SET_HIDDEN_MASTER':
            return {
                bookform: state.bookform.bookform,
                loading: false ,
                error: null ,
                form_id: state.bookform.form_id ,
                comp_id: state.bookform.comp_id ,
                city_id: action.payload ,
                infoComp: state.bookform.infoComp ,
                hiddenMasterStep: action.payload ,
                record_id: state.bookform.record_id ,
                u_phone: state.bookform.u_phone ,
                action: state.bookform.action ,
            }


        default:
            return state.bookform;
    }

}

export default updateBookform;