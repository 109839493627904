import updateServiceList from '../reducers/service-list';
import updateServiceCart from "../reducers/service-cart";
import updateEmployeeList from "./employee-list";
import updateSeancesList from "./employee-seances-list";
import updateBooktimesList from "./booktimes";
import updateBookdatesList from "./bookdates";
import updateSearchTerm from "./search-term";
import updateUserPhone from "./input-phone";
import updateBookform from "./bookform";
import updateBookRecord from "./bookrecord";
import updateTgUserData from "./tg-user-data";
import updateSmsCode from "./smscode";
import  activityReducer from './activityReducer';


/*const initialState = {
    servicesList: [
        {
            loading: true,
            error: null ,
            services: [
                {
                    title: "XS Подмышки+голени",
                    price_min: 2000,
                    price_max: 2000}
                ]} ,
    ],
    serviceCart:[
        {serviceCartItems: []}
    ],

    order: [] ,

    employeeList: []
} */



const reducer = (state , action) => {

    return{
        activity : activityReducer(state , action) ,
        servicesList : updateServiceList(state , action) ,
        serviceCart :  updateServiceCart(state , action) ,
        employeeList : updateEmployeeList(state, action) ,
        seancesList : updateSeancesList(state , action) ,
        booktimes: updateBooktimesList(state , action) ,
        bookdatesList: updateBookdatesList(state , action) ,
        term: updateSearchTerm(state , action) ,
        phone: updateUserPhone(state , action) ,
        bookform: updateBookform(state , action) ,
        bookrecord : updateBookRecord(state , action) ,
        tguserdata : updateTgUserData(state , action) ,
        smscode : updateSmsCode(state , action)

    };
};

export default reducer;