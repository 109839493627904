import React, { useEffect, useState } from 'react';
import ElementList from '../element-list';
import Bookform from '../bookform';
import { connect, useDispatch } from 'react-redux';
import { withClientstoreService } from '../hoc';
import { isObjEmpty } from '../../utils';
import { employeeAddedToCart, serviceAddedToCart } from '../../actions';
import { useNavigate } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { dateFormater } from '../../utils/date-formater';
import Box from '@mui/material/Box';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import TgUserData from '../tg-user-data/tg-user-data';
import MasterBlockHeader from './MasterBlockHeader';


const getNewElementMenu = (oldItem, stateCart) => {
    let itm = { ...oldItem };

    switch (oldItem.label) {
        case 'services':
            if (stateCart.services.length > 0) {
                itm = {
                    ...oldItem,
                    button: false,
                    info: 'Выбранные услуги',
                    sublist: true,
                    cart: stateCart.services,
                    avatar: { type: 'icon', src: null },
                };
            }
            return itm;
        case 'employees':
            const emp = stateCart.employee;
            if (typeof emp === 'object') {
                if (emp.id) {
                    itm = {
                        ...oldItem,
                        button: emp.id === -1 /*? true : false*/,
                        info: emp.master,
                        avatar: emp.id === -1 ? null : { type: 'avatar', src: emp.avatar },
                        subinfo: emp.specialization ? emp.specialization : null,
                    };
                }
            }
            return itm;

        case 'seance':
            const sDates = stateCart.seance;

            if (sDates) {
                itm = {
                    ...oldItem,
                    button: false,
                    info: sDates,
                    subinfo: stateCart.time,
                    avatar: { type: 'icon', src: null }, // цветная иконка с календарём
                };
            }
            return itm;
    }
};

//const _formId = '828420';
const setData = (cartItems, menuItems, hiddenMaster = false, record_action = 'book', stepsData = [], staff_name = 'специалист') => {
    // console.log("cartItems" , cartItems);
    const newMenu = [];

    const menuElements = hiddenMaster ? menuItems.filter((item) => item.id !== '/employees') : menuItems;

    menuElements.map((el, index) => {
        const nItem = getNewElementMenu(el, cartItems);
        // в элементы надо выставить параметр btn_disabled в true если идёт перенос записи
        const newItem = { ...nItem, btn_disabled: record_action === 'move' };
        // console.log("NEW ITEM" , newItem);
        if (stepsData.length > 0) {
            // console.log(">>>" , stepsData);
            if (el.label === 'employees') {
                const objNewTitle = stepsData.find((item) => item.step === 'master');
                newItem.title = objNewTitle.title ? objNewTitle.title : newItem.title;
                // console.log("newItem" , newItem);
            }
        }
        newMenu.push(newItem);
    });

    return newMenu;
};

const data = [
    {
        id: '/services',
        label: 'services',
        icon: 'add_task',
        title: 'Выбрать услугу',
        hidden: false,
        button: true,
        btn_disabled: false,
        info: null,
        sublist: false,
    },
    {
        id: '/employees',
        label: 'employees',
        icon: 'group_add_icon',
        title: 'Выбрать мастера',
        hidden: false,
        button: true,
        btn_disabled: false,
        info: null,
        sublist: false,
    },
    {
        id: '/timetable',
        label: 'seance',
        icon: 'calendar_month_icon',
        title: 'Выбрать дату',
        hidden: false,
        button: true,
        btn_disabled: false,
        info: null,
        sublist: false,
    },
];

const MainButtonBar = ({ show, onclick }) => {
    if (show) {
        return (
            <AppBar position='fixed' color='inherit' sx={{ top: 'auto', bottom: 0 }}>
                <Toolbar sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Link to='/'>
                        <Button
                            variant='contained'
                            size='large'
                            startIcon={<AccessAlarmIcon />}
                            sx={{
                                width: '343px',
                                borderRadius: '5px',
                                cursor: 'pointer',
                            }}
                            disableElevation
                            color='success'
                            onClick={onclick}
                        >
                            Записаться
                        </Button>
                    </Link>
                </Toolbar>
            </AppBar>
        );
    }
};
const fnShowMainBtn = (services, seance) => {
    if (services.length > 0 && seance !== null) return true;
    return false;
};

const tg = window.Telegram.WebApp;
const userData = tg.initDataUnsafe;

const FirstDisplay = ({ services, employee, seance, dateFormat, onAddedToCart, bookform, employeeList, masterAddToCart, onAddedEmployeeToCart }) => {
    const dispatch = useDispatch();

    const {
        bookform: { hiddenMasterStep, action, phone },
        bookform: {
            bookform: {
                data: { steps, staff_name },
            },
        },
    } = bookform;
    const { current, loading, employees } = employeeList;
    const [infoCurrentMaster, setInfoCurrentMaster] = useState({});

    const navigate = useNavigate();

    const onClickTgButton = () => {
        // если пользователь записывается на индивидуальную тренировку, то очищаем хранилище activity
        dispatch({ type: 'UPDATE_ACTIVITY', payload: {} });
        // если перенос записи,  то редиректить на компонент который отправит запись и выдаст сообщение,  что успешно
        // console.log("move" , action);
        if (action === 'move') {
            navigate('/moverecord');
        } else {
            navigate('/yourphone');
        }
    };


    let showMainBtn = false;
    let strSeanseDate = null;
    let strSeanceTime = null;

    tg.MainButton.text = 'Записаться';
    // tg.isClosingConfirmationEnabled = true;

    if (!isObjEmpty(seance)) {
        const seanceDate = dateFormat(seance.date);
        const seanceWeekDay = dateFormat(seance.date, 'LONGDAY');
        strSeanceTime = `${seance.time} , ${seanceWeekDay}`;
        strSeanseDate = `${seanceDate}`;
    }

    const cartItems = {
        services,
        employee,
        seance: strSeanseDate,
        time: strSeanceTime,
    };

    tg.MainButton.onClick(onClickTgButton);

    useEffect(() => {
        // console.log(fnShowMainBtn(services , seance));
        //tg.MainButton.onClick(onClickTgButton);
        fnShowMainBtn(services, seance) ? tg.MainButton.show() : tg.MainButton.hide();
    }, [services, seance]);

    useEffect(() => {
        if (hiddenMasterStep && employees.length > 0) {
            const field = employees.filter((el) => el.id === current);
            if (field.length > 0) {
                setInfoCurrentMaster(field[0]);
                // добавить мастера сразу в корзину
                masterAddToCart({ mid: current });
            }
            // console.log("employees" , field);
        }
    }, [hiddenMasterStep, employees]);

    const ndata = setData(cartItems, data, hiddenMasterStep, action, steps, staff_name);

    // console.log("ndata" , ndata);

    return (
        <>
        <Box sx={{ bgcolor: 'background.paper' }}>
            <TgUserData user={userData} />
            {hiddenMasterStep && <MasterBlockHeader master={infoCurrentMaster} />}
            <ElementList data={ndata} delCart={onAddedToCart} employeeAddCart={onAddedEmployeeToCart} />

            {/*{process.env.NODE_ENV === 'production' && <MainButtonBar show={true} onclick={onClickTgButton} />}*/}
            {process.env.NODE_ENV === 'development' && (
                <Button
                    variant='contained'
                    size='large'
                    startIcon={<AccessAlarmIcon />}
                    sx={{
                        width: '343px',
                        borderRadius: '5px',
                        cursor: 'pointer',
                    }}
                    disableElevation
                    color='success'
                    onClick={onClickTgButton}
                >
                    Записаться
                </Button>
            )}

        </Box>

    </>
    );
};

const mapStateToProps = ({ serviceCart: { serviceCartItems, employeeCartItems, seanceDate }, bookform, employeeList }) => {
    return {
        services: serviceCartItems,
        employee: employeeCartItems,
        seance: seanceDate,
        employeeList,
        bookform: { bookform },
    };
};

const mapDispatchToProps = (dispatch, { clientstoreService }) => {
    //const  = ownProps;
    return {
        dateFormat: (date, format = 'DAY_MON') => dateFormater(date, format), //clientstoreService._getTabDate(date , format) ,
        onAddedToCart: (id) => dispatch(serviceAddedToCart(id)),
        onAddedEmployeeToCart: (params) => dispatch(employeeAddedToCart(params)),
        masterAddToCart: (params) => dispatch(employeeAddedToCart(params)),
    };
};

export default withClientstoreService()(connect(mapStateToProps, mapDispatchToProps)(FirstDisplay));
