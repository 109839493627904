import React from 'react';
import { ClientstoreServiceConsumer } from '../clientstore-service-context'

const withClientstoreService = () => (Wrapped) => {

    return (props) => {
        return (
            <ClientstoreServiceConsumer>
                {
                    (clientstoreService) => {
                        return (<Wrapped {...props}
                                         clientstoreService={clientstoreService}/>);
                    }
                }
            </ClientstoreServiceConsumer>
        );
    }
};

export default withClientstoreService;