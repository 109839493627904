import * as React from 'react';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const SnackbarNotify = ({variant , message="Какая-то ошибка...", boolOpen=false , setClose}) => {
    const [open, setOpen] = React.useState(boolOpen);

    // const handleClick = () => {
    //     setOpen(true);
    // };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setClose();
        setOpen(false);
    };

    return (

            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity={variant} sx={{ width: '100%' }}>
                    {message}
                </Alert>
            </Snackbar>

    );
}

export default SnackbarNotify;