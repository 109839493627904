import React from 'react'
import {Avatar, Button, Card, CardHeader, Divider} from "@mui/material";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import {  blue , grey } from '@mui/material/colors';

const TopBar = ({filial , data , handleReset}) => {
    return(
        <>
            <Card variant="outlined" sx={{border: 0 , backgroundColor: grey[100]  }}>
                <CardHeader
                    avatar={
                        data.logo===null ?
                            <Avatar sx={{ bgcolor: blue[500] }}>
                                <LocationOnIcon  />
                            </Avatar>
                            :
                            <Avatar src={data.logo} />

                    }
                    action={
                        <Button variant="text" onClick={() => handleReset()}>Изменить</Button>
                    }
                    title={data.title}
                    subheader={`${data.subheader} `}
                />
            </Card>
            {/*<Divider/>*/}
        </>
    )
}

export default TopBar;