import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import MySpinner from "../spinner";
import SnackbarNotify from "../snackbar-notify";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import {alpha} from "@mui/material/styles";
import {grey} from "@mui/material/colors";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import InputLabel from "@mui/material/InputLabel";
import InputBase from "@mui/material/InputBase";
import React, {useEffect, useState} from "react";
import {TextField} from "@mui/material";

const tg = window.Telegram.WebApp;

const paperStyle = { p: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: '100%' ,
    borderRadius: 1 ,
    backgroundColor: alpha(grey[700], 0),
    '&:hover': {
        backgroundColor: alpha(grey[700], 0.1),
    }
};

const TF = {maxWidth: "48px"}


const SmsCode = ({checkSms , error , phone}) => {


    const [name, setName] = React.useState('');
    const [ch1, setCh1] = React.useState('');
    const [ch2, setCh2] = React.useState('');
    const [ch3, setCh3] = React.useState('');
    const [ch4, setCh4] = React.useState('');
    const [sCode, setCode] = React.useState('');
    const inputFirst = React.useRef();
    const inputSecond = React.useRef();
    const inputThird = React.useRef();
    const inputFour = React.useRef();

    const disabled = error ? true : false;

    const [seconds, setSeconds] = useState(60);

    useEffect(() => {
        const timer = setInterval(() => {
            if (seconds > 0) {
                setSeconds(seconds - 1);
            }
        }, 1000)

        return () => clearInterval(timer)
    }, [seconds]);



    const getInputNumbersValue = function (value) {
        // Return stripped input value — just numbers
        return value.replace(/\D/g, '');
    }

    // const char_1 = inputFirst.current.value;
    // const char_2 = inputSecond.current.value;
    // const char_3 = inputThird.current.value;
    // const char_4 = inputFour.current.value;

    useEffect(() => {

        if(ch1.length === 4){
            const arCh1 = ch1.split('');
            console.log("ch1" , arCh1);
            setCh1(arCh1[0]);
            setCh2(arCh1[1]);
            setCh3(arCh1[2]);
            setCh4(arCh1[3]);
        }
        if(ch1.length === 1){
            inputSecond.current.focus();
        }
        if(ch2.length === 1){
            inputThird.current.focus();
        }
        if(ch3.length === 1){
            inputFour.current.focus();
        }
        if(ch4.length === 1){
            // inputFour.current.focus();
            const code = `${ch1}${ch2}${ch3}${ch4}`
            setCode(code);
            // checkSms( code)
        }

        // console.log(`1: ${ch1.length} |2: ${ch2.length}  |3: ${ch3.length}  |4: ${ch4.length} `);
    } );

    useEffect(() => {
        if(sCode.length > 3){
            checkSms( sCode)
        }
    } , [sCode]);

    return (
        <>
            <Box sx={{m:'20px'}}>
                <Stack

                    justifyContent="center"
                    alignItems="center"
                    spacing={2}
                    sx={{marginBottom: '20px'}}

                >
                    {/*{bookrecord.loading?<MySpinner />:null}*/}
                    {/*{error ? <SnackbarNotify variant="error" boolOpen={true} message={error} setClose={snackBarClose}/> : null}*/}

                    <Typography variant="h6" component="div">
                        Введите код из SMS
                    </Typography>
                    <Typography sx={{mt:2 }} color="text.secondary">
                        Отправили на номер {phone}
                    </Typography>

                    <Stack direction="row" spacing={2}>
                        <TextField
                            inputRef={inputFirst}
                            hiddenLabel
                            variant="filled"
                            sx={TF}
                            value={ch1}
                            onChange={(event) => {
                                setCh1(getInputNumbersValue(event.target.value));
                                // console.log(name);
                                // if(name.length > 0)inputSecond.current.focus();
                            }}
                            disabled={disabled}

                        />
                        <TextField
                            inputRef={inputSecond}
                            hiddenLabel
                            variant="filled"
                            sx={TF}
                            value={ch2}
                            onChange={(event) => {
                                setCh2(getInputNumbersValue(event.target.value));

                            }}
                            disabled={disabled}
                        />
                        <TextField
                            inputRef={inputThird}
                            hiddenLabel
                            variant="filled"
                            sx={TF}
                            value={ch3}
                            onChange={(event) => {
                                setCh3(getInputNumbersValue(event.target.value));

                            }}
                            disabled={disabled}
                        />
                        <TextField
                            inputRef={inputFour}
                            hiddenLabel
                            variant="filled"
                            sx={TF}
                            value={ch4}
                            onChange={(event) => {
                                setCh4(getInputNumbersValue(event.target.value));

                            }}
                            disabled={disabled}
                        />
                    </Stack>
                    <Typography sx={{mt:2 }} color="text.secondary">
                        Повторно код можно запросить через {seconds} сек.
                    </Typography>
                </Stack>




            </Box>
        </>
    )
}

export default SmsCode;