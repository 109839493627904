
   // var phoneInputs = document.querySelectorAll('input[data-tel-input]');
   //

   const getInputNumbersValue = function (input) {
       // Return stripped input value — just numbers
       return input.value.replace(/\D/g, '');
   }

   const onPhonePaste = function (e) {
       let input = e.target,
           inputNumbersValue = getInputNumbersValue(input);
       let pasted = e.clipboardData || window.clipboardData;
       if (pasted) {
           let pastedText = pasted.getData('Text');
           if (/\D/g.test(pastedText)) {
               // Attempt to paste non-numeric symbol — remove all non-numeric symbols,
               // formatting will be in onPhoneInput handler
               input.value = inputNumbersValue;
               return;
           }
       }
   }

   const onPhoneInput = function (e) {
       let input = e.target,
           inputNumbersValue = getInputNumbersValue(input),
           selectionStart = input.selectionStart,
           formattedInputValue = "";

       if (!inputNumbersValue) {
           return input.value = "";
       }

       if (input.value.length != selectionStart) {
           // Editing in the middle of input, not last symbol
           if (e.data && /\D/g.test(e.data)) {
               // Attempt to input non-numeric symbol
               input.value = inputNumbersValue;
           }
           return;
       }
       // RU +7(###)###-##-##
       // BY +375(##)###-##-##
       // KZ "+7(6##)###-##-##",
       // KZ "+7(7##)###-##-##",
       // UA +380(##)###-##-##

       // AZ +994-##-###-##-##
       // AM +374-##-###-###

       // KG +996(###)###-###
       // MD +373-####-####
       // TJ +992-##-###-####

       // TM +993-#-###-####

       // UZ +998-##-###-####

       if (["7", "8", "9"].indexOf(inputNumbersValue[0]) > -1) {
           if (inputNumbersValue[0] == "9") inputNumbersValue = "7" + inputNumbersValue;
           let firstSymbols = (inputNumbersValue[0] == "8") ? "8" : "+7";
           formattedInputValue = input.value = firstSymbols + " ";
           if (inputNumbersValue.length > 1) {
               formattedInputValue += '(' + inputNumbersValue.substring(1, 4);
           }
           if (inputNumbersValue.length >= 5) {
               formattedInputValue += ') ' + inputNumbersValue.substring(4, 7);
           }
           if (inputNumbersValue.length >= 8) {
               formattedInputValue += '-' + inputNumbersValue.substring(7, 9);
           }
           if (inputNumbersValue.length >= 10) {
               formattedInputValue += '-' + inputNumbersValue.substring(9, 11);
           }
       } else if (["3"].indexOf(inputNumbersValue[0]) > -1){
           // console.log("inputNumbersValue" , inputNumbersValue);
           if (inputNumbersValue[0] == "3") formattedInputValue = input.value = "+3";
           if(inputNumbersValue[1] === "7"){
               let fSymbol = "+375";
               formattedInputValue = input.value = fSymbol + " ";
               if (inputNumbersValue.length > 3) {
                   formattedInputValue += '(' + inputNumbersValue.substring(3, 5);
               }
               if (inputNumbersValue.length >= 6) {
                   formattedInputValue += ') ' + inputNumbersValue.substring(5, 8);
               }
               if (inputNumbersValue.length >= 9) {
                   formattedInputValue += '-' + inputNumbersValue.substring(8, 10);
               }
               if (inputNumbersValue.length >= 11) {
                   formattedInputValue += '-' + inputNumbersValue.substring(10, 12);
               }

           }else{
               formattedInputValue = '+' + inputNumbersValue.substring(0, 16);
           }

       }
       else {
           formattedInputValue = '+' + inputNumbersValue.substring(0, 16);
       }
       input.value = formattedInputValue;
   }

   const onPhoneKeyDown = function (e) {
       // Clear input after remove last symbol
       let inputValue = e.target.value.replace(/\D/g, '');
       if (e.keyCode == 8 && inputValue.length == 1) {
           e.target.value = "";
       }
   }

    const inputMaskPhone = (phoneInput) => {
       // for (let phoneInput of phoneInputs) {
           phoneInput.addEventListener('keydown', onPhoneKeyDown);
           phoneInput.addEventListener('input', onPhoneInput, false);
           phoneInput.addEventListener('paste', onPhonePaste, false);
       // }
    }

    const ListenKeyAndPaste = (phoneInput) => {
        phoneInput.addEventListener('keydown', onPhoneKeyDown);
        //phoneInput.addEventListener('input', onPhoneInput, false);
        phoneInput.addEventListener('paste', onPhonePaste, false);
    }

   export {
       inputMaskPhone ,
       onPhoneKeyDown ,
       onPhoneInput ,
       onPhonePaste ,
       ListenKeyAndPaste

   } ;






