import {isObject} from '../utils/empty-object'
const any_master_label = "Не имеет значения" ;

const updateCartItems = (cartItems, item, idx) => {

    if (item.count === 0) {
        return [
            ...cartItems.slice(0, idx),
            ...cartItems.slice(idx + 1)
        ];
    }

    if (idx === -1) {
        return [
            ...cartItems,
            item
        ];
    }

    return [
        ...cartItems.slice(0, idx),
        item,
        ...cartItems.slice(idx + 1)
    ];
};

const updateServiceCartItem =( service , item={} , quantity) => {

    const {
        id = service.id ,
        count = 0 ,
        title = service.title,
        total = 0
    } = item;
    //console.log('CART ITEM' , quantity*service.price_max);
    const price = service.price_max > 0 ? service.price_max : service.price_min;
    return {
        id,
        title,
        count: count + quantity,
        total: total + quantity*price ,
    };

}

const updateEmployeeCartItem =( employee , item={} , times) => {
    const {
        id = employee.id ,
        time = employee.time,
        master = employee.name
    } = item;

    return {
        id,
        master,
        time
    };

}

const updateServiceOrder = (state, serviceId) => {
    const { servicesList: {services}, serviceCart: {serviceCartItems , employeeCartItems , seanceDate , orderTotal} } = state;

    const arServices = services.services;
    //console.log(arServices);
    const service = arServices.find((el) => el.id  === serviceId);
    const itemIndex = serviceCartItems.findIndex((el) => el.id === serviceId);
    //console.log(serviceCartItems);
    let quantity = 1;
    const item = [];
    if(itemIndex > -1 ){
        const item = serviceCartItems[itemIndex];
        if(item.count > 0 ){
            quantity = 0;
        }
    }
    const price = service.price_max > 0 ? service.price_max : service.price_min;
    let total = (quantity === 1 ) ? orderTotal+price : orderTotal-price;
    //const quantity = (item.count == 0) ? 1 : 0;
    const newItem = updateServiceCartItem(service, item, quantity);

    return {
        serviceCartItems: updateCartItems(serviceCartItems, newItem, itemIndex),
        employeeCartItems: employeeCartItems ,
        seanceDate: seanceDate,
        orderTotal: total
    };
};

// если переносят запись обновить корзину
const updateCartFromRecordInfo = (state , params) => {
    // console.log("state bookform" , state.bookform.hiddenMasterStep);
    let newObj = {};
    let newObjStaff = {};
    let newOrderToral = 0;
    if(params.services.length > 0){
        newObj = params.services.map(item => {
            newOrderToral += item.cost;
            return {id: item.id , title: item.title , count: 1 , total: item.cost }
        });
    }

    if(isObject(params.staff)){
        const staff_id = parseInt(params.staff.id);
        if(staff_id > 0){
            newObjStaff = {
                id: params.staff.id ,
                master: params.staff.name ,
                specialization: params.staff.specialization ,
                avatar: params.staff.avatar  ,
                bookable: true
            }
        }
    }

    // if(params.staff){
    //     newObjStaff = params.staff.map(it => {
    //         return {id: it.id , master: it.name , specialization: it.specialization , avatar: it.avatar  , bookable: true}
    //     })
    // }
   // const services =
    // let seanceItem = {...state.serviceCart.seanceDate};
    return {
        serviceCartItems: newObj ,
        employeeCartItems: newObjStaff ,
        seanceDate: null ,
        orderTotal: newOrderToral ,
    }
};

const updateEmployeeOrder = (state, params) => {


    let employeeItem = {...state.serviceCart.employeeCartItems};
    let seanceItem = {...state.serviceCart.seanceDate};

    if(params.mid !== undefined){
        const { employeeList:{employees}, serviceCart: {employeeCartItems} } = state;
        if(params.mid === -1){
            employeeItem = {
                id : -1 ,
                master : any_master_label ,
                bookable: 1
            }
        }else{
            if(employees.length > 0){
                const employee = employees.find((el) => el.id  === params.mid);
                //const itemIndex = employeeCartItems.findIndex((el) => el.id === params.mid);

                employeeItem = {
                    id : employee.id ,
                    master : employee.name ,
                    bookable: employee.bookable ,
                    avatar: employee.avatar ,
                    specialization: employee.specialization
                }
            }

        }
    }

    if(params.date !== undefined) {

        if(params.date === null){

            seanceItem = null;
        }else{
            const time = params.time;
            const seanceDate = params.date;

            seanceItem = {date: params.date, time: params.time};
        }


        // return {
        //     ...state.serviceCart,
        //     employeeCartItems: newItem,
        //     seanceDate: {date: params.date, time: params.time}
        // }
    }

    return {
         ...state.serviceCart,
        employeeCartItems: employeeItem ,
        seanceDate: seanceItem
        } //updateCartItems(employeeCartItems, newItem, itemIndex) ,
};

const updateServiceCart = (state, action) => {


    if (state === undefined) {
        let serviceCartItems = [];
        let orderTotal = 0;
        let employeeCartItems = {id: -1 , master: any_master_label , bookable: 1};

        // if (localStorage.getItem("serviceCart.serviceCartItems")) {
        //     const timeLimit = JSON.parse(localStorage.getItem("serviceCart.serviceCartItems"))[1];
        //     if (timeLimit >= +new Date()) {
        //         serviceCartItems = JSON.parse(localStorage.getItem("serviceCart.serviceCartItems"))[0];
        //     }
        // }
        // if (localStorage.getItem("serviceCart.orderTotal")) {
        //     const timeLimit = JSON.parse(localStorage.getItem("serviceCart.orderTotal"))[1];
        //     if (timeLimit >= +new Date()) {
        //         orderTotal = JSON.parse(localStorage.getItem("serviceCart.orderTotal"))[0];
        //     }
        // }
        // if (localStorage.getItem("serviceCart.employeeCartItems")) {
        //     const timeLimit = JSON.parse(localStorage.getItem("serviceCart.employeeCartItems"))[1];
        //     if (timeLimit >= +new Date()) {
        //         employeeCartItems = JSON.parse(localStorage.getItem("serviceCart.employeeCartItems"))[0];
        //     }
        // }

        return {
            serviceCartItems: serviceCartItems,
            employeeCartItems: employeeCartItems ,
            seanceDate: null,
            orderTotal: orderTotal
        }
    }

    switch(action.type) {

        case 'SERVICE_ADDED_TO_CART':
            return updateServiceOrder(state, action.payload);
        case 'EMPLOYEE_ADDED_TO_CART':
            return updateEmployeeOrder(state, action.payload);
            default:
            return state.serviceCart;
        case 'UPDATE_CART_FROM_RECORD_INFO':
            return updateCartFromRecordInfo(state, action.payload);
        case 'SERVICE_CART_CLEAR':
            return {
                serviceCartItems: [] ,
                employeeCartItems : {id: -1 , master: any_master_label , bookable: 1} ,
                seanceDate: null,
                orderTotal: 0 ,
            }
    }
};

export default updateServiceCart;