import * as React from 'react';
import { useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import CssBaseline from '@mui/material/CssBaseline';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import IconButton from '@mui/material/IconButton';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Link } from "react-router-dom";
import SearchPanel from "../search-panel";
import {getHeader} from '../../utils/location-header-name';
import { grey } from '@mui/material/colors';


function ElevationScroll(props) {

    const { children, window } = props;
    // Note that you normally won't need to set the window ref as useScrollTrigger
    // will default to window.
    // This is only being set here because the demo is in an iframe.
    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 0,
        target: window ? window() : undefined,
    });

    return React.cloneElement(children, {
        elevation: trigger ? 4 : 0,
    });
}


export default function ElevateAppBar(props) {
    const navigate = useNavigate();
    const {location , staffName} = props;
    //console.log(location);
    return (
        <React.Fragment>
            <CssBaseline />
            <ElevationScroll {...props}>
                <AppBar color='default'>
                    <Toolbar variant="dense" >
                        <Link onClick={() => navigate(-1)} sx={{ textDecoration: 'none', color: grey }}>
                            <IconButton edge="start" color="inherit" aria-label="menu" sx={{ mr: 1 }}>
                                <ArrowBackIosIcon />
                            </IconButton>
                        </Link>
                        <Typography variant="body2" color="inherit" component="div" sx={{ flexGrow: 1 }}>
                            {getHeader(location.pathname , staffName)}
                        </Typography>
                         <SearchPanel />
                    </Toolbar>

                </AppBar>
            </ElevationScroll>
            <Toolbar sx={{minHeight: 48}} />

        </React.Fragment>
    );
}