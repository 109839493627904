import React , {useEffect} from 'react';
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Chip from "@mui/material/Chip";
import Box from "@mui/material/Box";
import {Link, useNavigate} from "react-router-dom";
import Button from "@mui/material/Button";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

const tg = window.Telegram.WebApp;

const ServicesFooterBar = ({show , total , count}) => {

    const tgview = true;
    const navigate = useNavigate();
    const onClickTgButton = () => {
        navigate("/", { state: { isRedirect: true } });
    }

    tg.MainButton.onClick(onClickTgButton);

    useEffect(() => {
        tg.MainButton.text = "Продолжить";
        show ? tg.MainButton.show() :  tg.MainButton.hide() ;

    } , [show]);

    if(show){
        return(
            <AppBar position="fixed" color="inherit" sx={{ top: 'auto', bottom: 0 }} >
                <Toolbar>
                    <Typography variant="body2" component="div">
                        <Chip label={count} color="primary" />
                        { ` на сумму ${total} руб.` }
                    </Typography>

                    <Box sx={{ flexGrow: 1 }} />

                    { tgview ? null :
                    <Link to='/'>
                        <Button variant="contained" size="small" endIcon={<ArrowForwardIcon />}
                                sx={{

                                    borderRadius: 30 ,
                                    padding: '2px 10px' ,
                                    cursor: 'pointer'}}

                        >
                            Далее
                        </Button>
                    </Link>
                    }
                </Toolbar>
            </AppBar>
        );
    }
};

export default ServicesFooterBar;