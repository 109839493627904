import React from "react";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';


const MySpinner = () => {
    return(

            <Box sx={{ display: 'flex' , justifyContent:'center', alignItems:'center', marginTop: 8 }}>
                <CircularProgress />
            </Box>

    );
}

export default MySpinner;