import React , {useEffect} from 'react';
import {connect} from "react-redux";
import {withClientstoreService} from "../hoc";
import {
    fetchBookform,
    setCurrentFormId,
    setCurrentCompId,
    setCurrentRecordId,
    setCurrentUserPhone,
    setHiddenMaster,
    setCurrentCityId,
    serviceCartClear,
    fetchEmployees, employeeAddedToCart,
    getRecordById, setUserPhone
} from "../../actions";
import SnackbarNotify from "../snackbar-notify";
import CircularProgress from "@mui/material/CircularProgress";
import SkeletonMainPage from "../skeletons/SkeletonMainPage";


// const Bookform = (props) => {
//     const { setFormID , getBookForm , bookform , loading , error , formId} = props;
//
//     // useEffect(() => {
//     //     let obj = {
//     //         formId
//     //     }
//     //     setFormID(formId);
//     //     //console.log(obj);
//     //     getBookForm(obj);
//     // } , []);
//
//     return (
//         <div>
//
//         </div>
//     );
// };

const Bookform = (props) => {


    const {formId , bookform:{ bookform ,form_id , loading , error  } ,
        setFormID  ,
        setCompID ,
        setCityID ,
        setRecId ,
        getBookForm ,
        getLoading ,
        employees ,
        eLoading ,
        filialsShow ,
        clearRedux ,
        cartClear ,
        fetchEmployees ,
        setHiddenMaster ,
        getStaffName ,
        recordId ,
        typeAction ,
        recordInfo ,
        uPhone ,
        setUserYCPhone ,
        setPhone
    } = props;


    // const steps = bookform.data.steps;
    const baseStaffName = {nominative: 'Специалист', genitive: 'Специалиста', plural: 'Специалисты'}


    // Настройки формы ЮК / если отключен выбор мастера
    const steps = bookform.data ? bookform.data.steps : [];
    const staff_name = bookform.data ? bookform.data.staff_name : baseStaffName;


    // получаем настройки для названия специалистов из ЮК формы
    useEffect(() => {
        // console.log("1 >" , staff_name );
        if(staff_name.plural !== 'Специалисты'){
            // console.log("2 >>" , staff_name );
            getStaffName(staff_name);
        }
    } , [staff_name])

    useEffect(() => {
        const masterStep = steps.filter(el => el.step === 'master');
        let hiddenMasterStep = false;
        let masterId = 0;

        if(Array.isArray(masterStep) && masterStep.length > 0){

            hiddenMasterStep = masterStep[0]["hidden"];
            masterId = masterStep[0]["default"];

            if(hiddenMasterStep){
                setHiddenMaster(hiddenMasterStep);
                // готовим фильтр
                const objFilter = {
                    id: undefined,
                    company_id :  bookform.data.company_id ,
                    datetime: '',
                    services: [],
                    term: '',
                    defMasterId: masterId
                };
                // console.log("OBJ" , objFilter);
                fetchEmployees(objFilter);

            }
        }
    } , [steps]);



    // useEffect(() => {
    //     if(parseInt(bookform.data.group_id ) > 0){
    //         filialsShow(true);
    //     }
    // } , [bookform])

    const handleReset = () => {
        // console.log("reset handle");
        cartClear();
    }

    // useEffect(() => {
    //
    //     if(clearRedux){
    //
    //         // очистим корзину в редакс если человек открыл другую форму
    //         handleReset();
    //         localStorage.clear();
    //         console.log("klocal" , localStorage.getItem("serviceCart.serviceCartItems"));
    //
    //     }
    // } , [clearRedux])

    useEffect(() => {
        if(clearRedux){

            // очистим корзину в редакс если человек открыл другую форму
            handleReset();
            // localStorage.clear();
           // console.log("klocal" , localStorage.getItem("serviceCart.serviceCartItems"));

        }

        if(formId){
            let obj = {
                formId
            }
            setFormID(formId);
            // если перенос записи
            if(recordId){
                // если передется ID записи для переноса, то получаем информацию по записи чтобы заполнить Корзину
                // console.log("RECC" , recordId);
                let act = 'move';
                if(typeAction === 'reorder')act='reorder';
                setRecId({id: recordId , action: act});
                recordInfo({shop_id: formId ,  record_id: recordId});
            }
            // если передан номер телефона
            if(uPhone){
                setUserYCPhone(uPhone); // это запись номера в bookform
                const fields = {
                    uname: "",
                    phone: `+${uPhone}`
                }
                setPhone(fields); // это чтобы в форму автоматом подставилось


            }
            // console.log(obj);
            getBookForm(obj);

        }else{
            if(!bookform.success){
                throw new Error("invalid shop id");
            }
        }



    } , []);

    useEffect(() => {

       // console.log("use effect bookform" , loading);
        getLoading(loading);
        // console.log("Array.isArray(bookform)" , Object.prototype.toString.call(bookform) === '[object Object]');

    } , [loading]);

    useEffect(() => {

            if(Object.prototype.toString.call(bookform) === '[object Object]'){
                const gr_id = parseInt(bookform.data.group_id);
                if(gr_id > 0){
                    filialsShow(true);
                }
            }

    } , [bookform])




   // console.log("BookFCont" , `=> ${formId} => ${form_id}`);
        // <SnackbarNotify variant="error" message="Не передан Shop ID " boolOpen={true}/>


    // if(bookform.bookform.data.company_id){
    //      // throw new Error("Произошла ошибка");
    //     return <CircularProgress />
    // }

    return null;

    // return(
    //
    //     <div>ФОРМА {formId}</div>
    // );

}

const mapStateToProps = (state) => {
    const {bookform  , loading , error , employeeList  } = state;
    return {
        bookform ,
        success: bookform.success ,
        loading ,
        error ,
        form_id: bookform.form_id ,
        city_id: bookform.city_id ,
        comp_id: bookform.comp_id ,
        // record_id : bookform.record_id ,
        employees: employeeList.employees,
        eLoading: employeeList.loading,
        eError: employeeList.error,


    }
};
const mapDispatchToProps = (dispatch , {clientstoreService}) => {

    //const  = ownProps;
    return {
        setPhone : (fields) => dispatch(setUserPhone(fields)) ,
        getBookForm : fetchBookform(clientstoreService , dispatch) ,
        setFormID: (form_id) => dispatch(setCurrentFormId(form_id)) ,
        setCityID: (city_id) => dispatch(setCurrentCityId(city_id)) ,
        setCompID: (comp_id) => dispatch(setCurrentCompId(comp_id)) ,
        setRecId: (rec_id) => dispatch(setCurrentRecordId(rec_id)) ,
        setUserYCPhone: (phone) => dispatch(setCurrentUserPhone(phone)) ,
        setHiddenMaster: (hid) => dispatch(setHiddenMaster(hid)) ,
        cartClear : () => dispatch(serviceCartClear()) ,
        fetchEmployees: fetchEmployees(clientstoreService, dispatch),
        recordInfo: getRecordById(clientstoreService, dispatch) ,

    }

}

export default withClientstoreService()(connect(mapStateToProps , mapDispatchToProps)(Bookform));