import React from 'react';
import Chip from "@mui/material/Chip";
import FaceIcon from '@mui/icons-material/Face';
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";

import Divider from "@mui/material/Divider";

const EmployeeAnyItem = ({addItemCart}) => {
    const handleClick = () => {
        //console.log("Chip click");
        addItemCart(-1);
    }
    return (
        <React.Fragment>
            <Stack sx={{padding: '0px 16px 10px 16px'}}>
                <Chip icon={<FaceIcon />}
                      label="Не имеет значения"
                      variant="outlined"
                      size="small"
                      onClick={handleClick}
                />
            </Stack>
        </React.Fragment>

    );
};

export default EmployeeAnyItem;