import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import {useEffect} from "react";
import SmsCode from "../sms-code";
import {CircularProgress, Stack} from "@mui/material";
import Alert from "@mui/material/Alert";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function FullScreenDialog({onOpen , onClose , loading , handle , error , info}) {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    useEffect(() => {
        setOpen(onOpen);
    } , [onOpen]);

    // const handleClose = () => {
    //     setOpen(false);
    // };

    return (
        <div>

            <Dialog
                fullScreen
                open={open}
                onClose={() => onClose()}
                TransitionComponent={Transition}
            >
                <AppBar sx={{ position: 'relative' }}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={() => onClose()}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            Подтверждение
                        </Typography>
                        <Button autoFocus color="inherit" onClick={() => onClose()}>
                            Закрыть
                        </Button>
                    </Toolbar>
                </AppBar>
                {error &&
                    <Alert variant="outlined" severity="error">
                        {error}
                    </Alert>
                }
                {loading &&
                    <>
                    <Stack

                        justifyContent="center"
                        alignItems="center"
                        spacing={2}
                        sx={{marginTop: '20px'}}

                    >
                        <CircularProgress />
                    </Stack>
                    </>

                }
                {!loading &&
                    <SmsCode checkSms={handle} error={error} phone={info.phone}/>
                }

            </Dialog>
        </div>
    );
}