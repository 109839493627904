const activityReducer = (state, action) => {
    if (state === undefined) {
        return {};
    }

    switch (action.type) {
        case 'UPDATE_ACTIVITY':
            return action.payload;
        default:
            return state.activity;
    }
};
export default activityReducer;
